.gantt {
  &_container {
    margin-left: -50px;
  }
}

@media (max-width: 768px) {
  .gantt {
    &_container {
      margin-left: -20px;
    }
  }
}

@media (max-width: 600px) {
  .gantt {
    &_container {
      margin: 32px -20px 0;

      &__fullScreen {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        z-index: 10003;
        background: #fff;

        .gantt_button {
          margin-top: 20px;
          margin-bottom: 12px;
        }

        .indiana-scroll-container {
          height: calc(100% - 56px);

          .gantt-container {
            max-height: unset;
            height: 100%;
          }
        }
      }
    }

    &_button {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: 20px;
      margin-bottom: 16px;

      &__text {
        font-size: 14px;
        line-height: 20px;
        margin-right: 10px;
      }
    }
  }
}