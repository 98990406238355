.card {
  border-radius: 8px;
  border: none;
}

.dashboard__card-hr {
  border-top: 1px solid #F4F4F5;
  margin: 12px 0;

  &.inRow {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.dashboard-new.card.noSpoiler {
  .dashboard__card-title__container {
    color: #101828;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    padding-bottom: 12px;
    margin: 40px 40px 12px;
  }

  .card-title + .card-body {
    border-top: none;
    padding: 0 40px;
    padding-bottom: 40px;
  }

  &.withLine .card-title {
    border-bottom: 1px solid #F4F4F5;
  }
}

.dashboard__card-title__container {
  display: flex;
  align-items: center;
}

.dashboard__card-title {
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  margin-bottom: 0;
  color: #101828;
}

h3.dashboard__card-title {
  font-size: 16px;
}

h4.dashboard__card-title {
  font-size: 14px;
  margin-bottom: 10px;
}

.dashboard__card-title__reset {
  display: inline-block;
  color: var(--primary-color);
  font-size: 12px;
  margin-left: 1rem;
}

.dashboard__card-title__reset-big {
  display: inline-block;
  color: var(--primary-color);
  font-size: 14px;
}

.dashboard__card-title__toggle {
  float: right;
}

.dashboard__switch {
  padding: 14px 0px;

  .switch-slider-felix {
    border-radius: 8px;
    background: rgba(16, 24, 40, 0.05);
    box-shadow: none;

    &:before {
      border-radius: 6px;
      box-shadow: 0px 0px 1px rgba(28, 41, 61, 0.2),
      0px 2px 4px rgba(28, 41, 61, 0.12);
    }
  }
}

.dashboard__switch-label {
  margin-bottom: 0;
  display: flex;

  .switch-slider-felix {
    margin-right: 8px;
  }
}

.dashboard__card-toggle-advanced {
  display: flex;
  font-size: 16px;
  width: 100%;
  color: #101828;
  font-weight: bold;

  // говно, но думать как лучше сделать мне лень.
  padding-top: 8px;
  padding-bottom: 10px;
}

.dashboard__advanced-filter-no-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.dashboard__filter-apply {
  display: flex;
  justify-content: center !important;
  font-size: 16px;
}

.dashboard__card-spoiler {
  padding-right: 20px;
}

.dashboard__card-spoiler::after {
  content: '';
  position: absolute;
  top: 1.25rem;
  right: 40px;
  width: 20px;
  height: 20px;
  background-image: url('../../images/arrow-bottom2.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: all 0.5s ease;
}

.dashboard__card-spoiler.open::after {
  transform: rotate(180deg);
}

.dashboard-new.card {

  margin-bottom: 12px;

  .advanced {

    .card-title + .card-body,
    &.hideHeader > .card-body {
      border-top: none;
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  .card-title + .card-body,
  &.hideHeader > .card-body {
    border-top: 1px solid #F4F4F5;
    padding-left: 40px;
    padding-right: 40px;
  }

  &.hideHeader > .card-body {
    padding: 40px;
  }

  .dashboard__card-spoiler.closed + .card-body {
    padding: 0;
  }

  &.simpleSpoiler {
    margin-top: 60px;
    background: transparent;
    margin-bottom: 0;

    & > .dashboard__card-spoiler,
    & > .card-body {
      padding: 0;
      margin: 0;
    }

    & > .dashboard__card-spoiler {
      color: #000000;
      font-size: 28px;
      line-height: 32px;
      font-weight: bold;

      &.open {
        margin-bottom: 20px;
      }
    }

    & > .dashboard__card-spoiler::after {
      right: auto;
      top: 0.5rem;
      margin-left: 10px;
    }


  }

  &.simpleSpoiler.advanced {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #F4F4F5;

    .dashboard__card-spoiler {
      line-height: 18px;
    }

    .dashboard__card-spoiler::after {
      margin-left: 100px;
      top: 1.6rem;
    }
  }
}

.card-title.dashboard__card-spoiler {
  padding: 1.25rem 40px;
  margin: 0;

  &.mini {
    padding: 10px 1.25rem;

    &::after {
      top: 10px;
    }
  }

  &.advanced {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.dashboard-result-table__container {

  .table-responsive {
    max-height: 500px;
  }

  .with-indicator {
    line-height: 6px;
  }

  // исправлялка для корректного отображения
  // почему то position: sticky у хедера и transform у этого элемента дают фигню
  .color-indicator {
    transform: none;
    margin: 0;
    width: 16px;
    height: 16px;
  }

  table > thead > tr > th,
  table > tfoot > tr > td,
  table > tbody > tr > td {
    vertical-align: middle;
    font-size: 14px;
    font-weight: 400;
  }

  table > tbody > tr > td {
    border-top: none;
    border-bottom: 1px solid #F4F4F5;;
    padding: 16px 10px;
  }

  table > thead > tr > th:nth-child(1) {
    border-radius: 8px 0px 0px 8px;
  }

  table > thead > tr > th:nth-last-child(1) {
    border-radius: 0px 8px 8px 0px;
  }

  table > tbody > tr > td:nth-child(1),
  table > thead > tr > th:nth-child(1),
  table > tbody > tr > td:nth-child(2),
  table > thead > tr > th:nth-child(2) {
    position: sticky;
  }

  table > thead > tr > th:nth-child(1),
  table > thead > tr > th:nth-child(2) {
    z-index: 3;
  }

  &.sticky-horizontal {

    table > tbody > tr > td:nth-child(1),
    table > tbody > tr > td:nth-child(2) {
      background-color: white;
    }

    table > tbody > tr > td:nth-child(1),
    table > thead > tr > th:nth-child(1) {
      left: 0px;
    }

    table > tbody > tr > td:nth-child(2),
    table > thead > tr > th:nth-child(2) {
      left: 60px;
      box-shadow: 3px 0px 2px -2px #e7e7e7;
    }
  }


  table > thead > tr > th,
  table > tfoot > tr > td {
    padding: 20px 10px;
    background: #F4F4F5;
    border-bottom: none;
    border-top: none;
    color: rgba(16, 24, 40, 0.5);
    position: sticky;
    top: 0px;
    border-left: 1px solid white;
    z-index: 2;
  }

  &.data-align-top {
    table > tbody > tr > td {
      vertical-align: top;
    }
  }

  &.mini {

    table > thead > tr > th,
    table > tfoot > tr > td {
      vertical-align: top;
      padding: 5px 5px;
    }

    table > tbody > tr > td {
      padding: 5px 5px;
    }
  }

  &.no-padding {

    table > thead > tr > th,
    table > tfoot > tr > td {
      vertical-align: top;
      padding: 5px 5px;
    }

    table > tbody > tr > td {
      padding: 5px 5px;
    }
  }

  &.no-expand-all {
    .expand-cell-header {
      pointer-events: none;
    }
  }

  .cascade-table-expand {
    padding: 0 !important;
  }
}

.stat-pie {
  position: absolute;
  font-size: 12px;
  line-height: 16px;
  box-shadow: 0px 0px 1px rgb(28 41 61 / 10%), 0px 12px 20px rgb(28 41 61 / 10%);
  border-radius: 8px;
  background: white;
  padding: 10px 20px;
  min-width: 100px;
  transition: .5s;
  transform: translate(-50%, -50%);
  pointer-events: none;

  .stat-pie__title {
    word-wrap: normal;
  }

  .stat-pie__cnt__container {
    white-space: nowrap;
  }

  .stat-pie__count {
    font-size: 12px;
    line-height: 16px;
    margin: 0 8px;
    display: inline-block;
  }

  &.no-transition {
    transition: none;
  }

  &.stat-pie__tooltip {
    position: inherit;
    transform: none;
    max-width: 140px;
    z-index: 10;

    &.nowrap {
      max-width: none;
    }

    &.large {
      padding: 16px;
      max-width: 300px;
    }

    &.mini {
      min-width: 1px;
    }
  }

  &.hide {
    opacity: 0;
  }

  .stat-pie__procent {
    font-size: 28px;
    line-height: 32px;
    color: #FF4747;
  }

  &.quarter-1 {
    transform: translate(10%, -35%);
  }

  &.quarter-2 {
    transform: translate(-90%, -40%);
  }

  &.quarter-3 {
    transform: translate(-90%, -35%);
  }

  &.quarter-4 {
    transform: translate(10%, -30%);
  }

  .stat-deviation-time__date {
    font-size: 14px;
    line-height: 20px;
    color: #101828;
    font-weight: bold;
  }

  .stat-deviation-time__text {
    color: #101828;
    font-size: 12px;
    line-height: 16px;
  }

  &.stat-deviation {
    max-width: 155px;
  }

  .stat-pie__color-label {
    width: 10px;
    height: 10px;
  }

  .bar-tooltip__date {
    font-size: 14px;
    line-height: 20px;
    color: #101828;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 4px;
  }

  .bar-tooltip__title {
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: rgba(16, 24, 40, 0.7);
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-bottom: 4px;
  }

  .bar-tooltip__color-title {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 4px;
    border-radius: 2px;
  }
}

.row .col-top {
  margin-bottom: auto;
}

.tabs-button__container {

  background: rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 2px;
  display: inline;
  margin-bottom: auto;
  user-select: none;

  .tabs-button__item {
    display: inline-block;
    border-radius: 6px;
    padding: 4px 12px;
    font-size: 13px;
    line-height: 20px;
    color: rgba(16, 24, 40, 0.7);
    cursor: pointer;
    transition: 0.3s;

    &.active {
      cursor: default;
      background: #FFFFFF;
      color: #101828;
      box-shadow: 0px 0px 1px rgba(28, 41, 61, 0.2), 0px 2px 4px rgba(28, 41, 61, 0.12);
    }
  }

  & + .tabs-button__container {
    margin-left: 10px;
  }
}

.margin-left-auto {
  margin-left: auto;
  margin-right: 1rem;
}

.margin-bottom {
  margin-bottom: 1rem !important;
}

.width-100 {
  width: 100%;
}

@media (min-width: 1600px) {
  .rt-125-none {
    display: none !important;
  }
}


.input-title__container.dashboard-calendar {
  margin-bottom: 0;
  width: 220px;

  .input-title__label {
    display: none;
  }

  .input-title__block {
    padding: 0;
  }

  .input-title__container-main {
    padding: 3px 12px;
    background: rgba(16, 24, 40, 0.05);
  }

  .date-rt__container .date-rt__indicators {
    top: -6px;
  }

  .DatePicker__input {
    cursor: default;
    line-height: 20px;
    font-size: 14px;
    color: rgba(16, 24, 40, 0.7);
  }
}

.dashboard__chart-head__container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.dashboard-chart__container {
  color: rgba(16, 24, 40, 0.7);
  font-size: 11px;
  line-height: 16px;
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
}

.dashboard-chart__legend-item {
  display: flex;
  align-items: center;
}

.dashboard-chart__legend-item {
  margin-right: 12px;
  align-items: flex-start;
}

.dashboard-chart__legend-marker {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 2px;
  margin-right: 4px;
  flex-shrink: 0;
}

.dashboard__chart__container {
  .dx-c-bs4-axis-opacity {
    stroke: #E8E8EE;
    stroke-dasharray: 2;
    opacity: 1;
  }

  .dx-c-bs4-crisp-edges {
    shape-rendering: initial;
  }

  #bottom-container #bottom-left {
    display: none;
  }

  // &.pie #center-axis-container > div {
  //   height: 200px;
  // }
}

.dashboard-status__container {
  display: flex;

  .dashboard-status__item {
    width: calc(100% / 3);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .dashboard-status__count {
    font-size: 28px;
    color: #101828;
    line-height: 32px;
    font-weight: bold;
  }

  .dashboard-status__name {
    font-size: 12px;
    line-height: 16px;
    color: #666666;
  }

  .dashboard-status__item + .dashboard-status__item {
    border-left: 1px solid #F4F4F5;
  }
}

.dashboard-status__diff {
  position: relative;
  display: inline-block;

  &.up,
  &.down,
  &.equal {
    margin-right: 1rem;
  }

  &:after {
    content: "↑";
    position: absolute;
    top: 0;
    left: calc(100% + 2px);
    line-height: 1.6rem;
    font-weight: 100;
    display: none;
  }

  &.up:after {
    color: #00D359;
    display: inline-block;
  }


  &.down:after {
    color: rgb(255, 109, 109);
    transform: rotate(180deg);
    line-height: 1.2rem;
    display: inline-block;
  }

  &.equal:after {
    color: #FFCA00;
    display: inline-block;
    content: "‐";
    top: -3px;

  }

  &.parent-color::after {
    color: inherit !important;
  }
}

.dashboard__pie-title__container {
  position: absolute;
  z-index: 1;
}

.dashboard__pie-title__item {
  position: absolute;
  transform: translate(-50%, -50%);
  font-size: 11px;
  line-height: 16px;
  color: rgba(16, 24, 40, 0.7);
  letter-spacing: 0.2px;
}

.dashboard__bar-title__item {
  position: absolute;
  font-size: 11px;
  line-height: 16px;
  color: rgba(16, 24, 40, 0.7);
  letter-spacing: 0.2px;

  & > div {
    transform: translate(-50%, -50%);
  }

  &.rotated > div {
    transform: translate(0, -50%);
  }
}

.dashboard.bs-popover-top {
  margin: 0;
}

.dashboard-checkbox.checkbox.radio {
  height: 24px;
  width: 22px;
  margin-right: 12px;

  .checkbox__label::before {
    border-radius: 24px;
    width: 22px;
    height: 22px;
    top: 0px;
    border: 1px solid rgba(16, 24, 40, 0.25);
    box-sizing: content-box;
  }

  .checkbox__label.checked::before {
    border-color: var(--primary-color);
  }

  .checkbox__label:after {
    background: black;
    border-radius: 24px;
    width: 8px;
    height: 8px;
    top: 8px;
    left: 8px;
  }
}

.dashboard-checkbox.checkbox {
  &.title-right {
    margin-left: auto;
  }

  &.no-margin-left {
    margin-left: 0;
  }

  padding-left: 20px;
  margin-left: 22px;

  .checkbox__label {
    margin-bottom: 0;
    padding-left: 10px;

    &::before {
      width: 20px;
      height: 20px;
      border: 1.5px solid rgba(16, 24, 40, 0.25);
      box-sizing: border-box;
      border-radius: 3px;
    }

    &:after {
      top: 7px;
      left: 3px;
      width: 14px;
      height: 14px;
      background-size: 14px;
    }

  }

  .checkbox__input:checked + .checkbox__label:after,
  .checkbox__label.checked:after {
    opacity: 0.8;
  }
}

.dashboard__chart-roles__container {
  display: flex;
  align-items: center;
  width: 150px;
}

.dashboard__chart-roles__title {
  color: rgba(16, 24, 40, 0.5);
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;

}

.dashboard__select {
  &.input-title__container {
    margin: 0;
    width: 510px;
    margin-left: 10px;
  }

  &.single-role {
    width: 200px;
  }

  &.mini {
    .input-title__label {
      display: none;
    }

    .select-rt .select-rt__menu {
      margin-left: 0;
      width: 100%;
    }

    .input-title__container-main {
      padding: 0;
      border: none;
      background: rgba(16, 24, 40, 0.05);
      border-radius: 8px;
    }

    .select-rt {
      font-size: 13px;
      line-height: 20px;
    }

    .input-title__block {
      padding: 0;
    }

    .select-rt__value-container {
      padding: 2px;
      margin-bottom: auto;
      user-select: none;
      flex-wrap: nowrap;
    }

    .select-rt__multi-value,
    .select-rt__single-value {
      display: inline-block;
      border-radius: 6px;
      padding: 4px 12px;
      transition: 0.3s;
      cursor: default;
      background: #FFFFFF;
      color: #101828;
      box-shadow: 0px 0px 1px rgb(28 41 61 / 20%), 0px 2px 4px rgb(28 41 61 / 12%);
      margin: 0;
      display: flex;
    }

    .select-rt__multi-value__label {
      font-size: inherit;
      padding: 0;
    }

    .select-rt__multi-value + .select-rt__multi-value {
      margin-left: 3px;
    }

    .select-rt__input {
      // caret-color: transparent;
      line-height: 28px;
      margin-left: 7px;
    }

    .select-rt .select-rt__placeholder {
      display: block;
      padding: 4px 12px;
    }

    .select-rt__multi-value__remove {
      margin: -5px;
      margin-right: -12px;
      border-radius: 0px 8px 8px 0px;
      margin-left: 2px;
      padding-left: 2px;
    }

    .select-rt__indicators {
      margin: 0;
    }
  }
}

.dashboard__roles_container {
  max-height: 450px;
  overflow: auto;

  .dashboard-result-table__container table > tbody > tr > td {
    border: none;
    padding: 6px 10px;
  }

  .dashboard-result-table__container.table-responsive {
    max-height: initial;
  }
}

.dashboard-role__list__no-select__container {
  margin-top: 100px;


  .dashboard-role__list__no-select__icon {
    margin: 20px;
  }

  .dashboard-role__list__no-select__title {
    font-size: 16px;
    line-height: 24px;
    color: #101828;
  }
}

.cfo-select__container.input-title__container {
  width: 400px;
}

.year-select__container.input-title__container {
  width: 59px;

  .select-rt__indicators {
    display: none;
  }

  .select-rt__single-value {
    max-width: none;
  }
}

.curator-chart__title {
  text-align: center;
  font-size: 12px;
  color: #6c757d;
  flex-grow: 1;
}

.dashboard__chart-fixed-height {
  overflow-y: auto;
  overflow-x: hidden;

  &.curatorSpi {
    max-height: initial;
    height: 397px;
  }
}

text.dashboard-no-data {
  font-size: 1.2em;
}

.dashboard-work-table-percent {
  font-size: 12px;
}

.dashboard-work__container {
  border-bottom: 1px solid #F4F4F5;
}

.dashboard-work-header__container {
  font-size: 12px;
  align-items: center;
  cursor: pointer;
  padding: 1rem 0;
}

.dashboard-work-header__name {
  font-size: 14px;
  align-items: center;
  font-weight: bold;
}

.arrow-list-item.work-group {
  height: 12px;
}

.dashboard__card-union > .dashboard-new + .dashboard-new {
  margin-top: -4rem;
}

.dashboard-work-group__container {
  margin-top: -12px;
}

.dashboard {

  &__table,
  .table,
  &__chart-fixed-height,
  &__chart-fixed-height-wrapper {
    overflow: auto;
  }

  &__chart-fixed-height {
    max-height: 350px;

    &-wrapper {
      min-width: 950px;
      overflow-x: hidden;
    }
  }

  &__statDashboard {
    padding: 7rem;

    &_icon {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      overflow: visible;
      padding: calc(7rem + 10px);
      pointer-events: none;
    }
  }

  &__historyReport,
  &__spiReport,
  &__cpiReport {
    .dashboard-new.card {
      min-height: 640px;
    }
  }

  &__rolesReport {
    .col-xl-7 {
      flex: 0 0 60%;
      max-width: 60%;
    }

    .col-xl-5 {
      flex: 0 0 40%;
      max-width: 40%;
    }

    .dashboard__select.input-title__container.dashboard__rolesReport_select {
      width: auto;
    }
  }

  &__roleLoadReport {
    .dashboard__chart-head__container {
      justify-content: space-between;
      margin-bottom: 0 !important;
    }

    &_tabsWrapper,
    &_selectWrapper {
      display: flex;
      flex-wrap: wrap;
    }

    .tabs-button__container,
    .input-title__container {
      margin: 0 10px 10px 0;
    }
  }

  &__projectWorkList {

    &_tableContainer,
    .dashboard-work-group__container {
      overflow: auto;
      padding-bottom: 10px;
    }

    &_tableContainer {
      max-height: 500px;

      table {
        min-width: 900px;
        max-height: unset !important;
      }
    }

    .dashboard__card-union > .dashboard-new + .dashboard-new {
      margin-top: -40px;
    }

    .dashboard-work-group__container {
      overflow: hidden;
    }
  }

  &__projectReport {
    .dashboard {
      &__projectTypeReport {
        min-height: 450px;
      }

      &__segmentReport {
        min-height: 550px;
      }
    }
  }

  &__segmentReport {
    .dashboard__chart__container {
      padding: 0 1rem;
    }
  }
}

@media (max-width: 1400px) {
  .dashboard {
    &__filter {
      .col-lg-2 {
        flex: 0 0 25%;
        max-width: 25%;
      }

      .col-lg-4 {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }

    &__curatorSpiReport {
      overflow: auto;
      padding-bottom: 10px;

      .curatorSpi {
        min-width: 500px;
      }
    }

    &__rolesReport {
      .col-xl-7 {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .col-xl-5 {
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 30px;
      }
    }

    &__projectReport {
      .col-md-6 {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 1300px) {
  .dashboard {
    &__projectWorkList {
      .dashboard-work-group__container {
        overflow: auto;

        .dashboard-work__container {
          min-width: 900px;

          .col-lg-1,
          .col-lg-2 {
            flex: 0 0 15%;
            max-width: 15%;
          }

          .col-lg-3 {
            flex: 0 0 25%;
            max-width: 25%;
          }

          .col-lg-5 {
            flex: 0 0 30%;
            max-width: 30%;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .dashboard-table__container table > thead > tr > th:nth-child(1) {
    z-index: 11 !important;
  }

  .dashboard-table__container table > tbody > tr > td:nth-child(1) {
    z-index: 10 !important;
  }

  .dashboard-table__container table > tbody > tr > td:nth-child(2),
  .dashboard-table__container table > thead > tr > th:nth-child(2) {
    left: unset !important;
  }

  .dashboard {

    &__historyReport,
    &__spiReport,
    &__cpiReport {
      flex: 0 0 100%;
      max-width: 100%;
    }

    &__budgetReport,
    &__rolesReport {
      .dashboard__chart-head__container {
        justify-content: space-between;
        margin-bottom: 0 !important;
      }

      .margin-left-auto {
        display: none;
      }

      .tabs-button__container,
      .dashboard__select {
        margin: 0 10px 10px 0;
      }

      .year-select__container {
        margin-right: 0;
      }
    }
  }
}

@media (max-width: 1100px) {

  table > tbody > tr > td:nth-child(1),
  table > thead > tr > th:nth-child(1),
  table > tbody > tr > td:nth-child(2),
  table > thead > tr > th:nth-child(2) {
    left: unset !important;
  }

  .dashboard {
    &__filter {

      .input-title__label,
      .dashboard__switch-title {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .dashboard {
    &__filter {

      .input-title__label,
      .dashboard__switch-title {
        font-size: 16px;
      }
    }

    &__historyReport,
    &__spiReport,
    &__cpiReport {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

@media (max-width: 900px) {
  .dashboard {
    &__filter {

      .input-title__label,
      .dashboard__switch-title {
        font-size: 14px;
      }
    }

    &__historyReport,
    &__spiReport,
    &__cpiReport {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

@media (max-width: 800px) {
  .dashboard {
    &__curatorStatus {
      .dashboard__chart-fixed-height {

        .col-md-5,
        .col-md-7 {
          flex: 0 0 50%;
          width: 50%;
        }

        .col-md-4 {
          flex: 0 0 calc(100% / 3);
          width: calc(100% / 3);
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .dashboard {
    .h1-felix {
      font-size: 24px;
      line-height: 32px;
    }

    .dashboard-new.card {
      margin-bottom: 16px;

      .card-title,
      .card-title + .card-body {
        padding: 20px;
      }

      .dashboard__card-spoiler.closed + .card-body {
        padding: 0;
      }

      &.simpleSpoiler {

        & > .dashboard__card-spoiler,
        & > .card-body {
          padding: 0;
        }

        & > .dashboard__card-spoiler {
          font-size: 24px;
          line-height: 32px;
        }
      }

      &.noSpoiler .dashboard__card-title__container {
        padding: 0 0 10px;
        margin: 20px 20px 10px;
      }
    }

    &__filter {
      .col-lg-4 {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .input-title__label,
      .dashboard__switch-title {
        font-size: 16px;
      }
    }

    .dashboard__chart-head__container {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    &__statDashboard {
      padding: 60px 0;

      &_icon {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        overflow: visible;
        padding: 70px 10px;
        pointer-events: none;
      }
    }

    &__statDashboardTwo {
      width: 50%;
    }

    &__curatorStatus {
      .dashboard__chart-fixed-height {

        .col-md-5,
        .col-md-7 {
          flex: 0 0 50%;
          width: 50%;
        }

        .col-md-4 {
          flex: 0 0 calc(100% / 3);
          width: calc(100% / 3);
        }
      }
    }

    &__avgDeviationTime {
      .dashboard__chart-head__container {
        margin-bottom: 0;
        justify-content: space-between;
      }

      .tabs-button__container,
      .dashboard-calendar {
        margin-bottom: 10px;
      }

      .margin-left-auto {
        display: none;
      }
    }

    &__rolesReport {
      .col-xl-7 {
        .dx-c-bs4-container {
          padding: 10px 0;

          .dashboard__chart-roles__container {
            width: 100px;
          }

          .list-group-item {
            padding-left: 0 !important;
          }
        }
      }

      .dashboard-role__list__no-select__container {
        margin-top: 30px;
      }
    }

    &__roleLoadReport {
      &_selectWrapper {
        flex-direction: column-reverse;
        align-items: flex-start;
      }

      &_tabsWrapper {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__projectWorkList {
      margin-top: 30px !important;

      .dashboard-work-group__container {
        .dashboard-work__container {

          .col-lg-1,
          .col-lg-2,
          .col-lg-3,
          .col-lg-5 {
            flex: 0 0 20%;
            max-width: 20%;
          }
        }
      }
    }

    &__projectReport {
      margin-top: 30px !important;
    }
  }
}

@media (max-width: 600px) {
  .dashboard {
    .h1-felix {
      font-size: 18px;
      line-height: 24px;
    }

    .dashboard-new.card {
      margin-bottom: 12px;

      &.simpleSpoiler {
        & > .dashboard__card-spoiler {
          font-size: 18px;
          line-height: 24px;
          padding-right: 20px;

          &::after {
            top: 2px;
            right: 0;
          }
        }
      }

      &.simpleSpoiler.advanced {
        & > .dashboard__card-spoiler {
          padding-right: 0;

          &::after {
            top: 27px;
            right: 20px;
          }
        }
      }
    }

    &__filter {
      .dashboard__card-title__resetWrapper {
        width: 100%;
        margin: 20px 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .dashboard__card-title__reset,
      .dashboard__card-toggle-advanced {
        font-size: 14px;
        line-height: 20px;
        margin: 0;
      }

      .rc-slider-tooltip-placement-top {
        top: 23px !important;
      }
    }

    &__tableDashboard {
      .dashboard__card-title__container {
        align-items: flex-start;
      }

      &_upload {
        margin-left: 10px;
      }
    }

    &__statDashboard {
      padding: 0;
      display: flex;
      flex-direction: column-reverse;
    }

    &__tooltipMobileShow {
      margin-top: 10px;

      &_item {
        display: flex;
        padding: 12px 0;
        border-bottom: 1px solid #F4F4F5;

        &:last-child {
          border-bottom: none;
        }
      }

      &_percent {
        font-size: 28px;
        line-height: 32px;
        position: relative;
        min-width: 60px;

        &.percent {

          &__up,
          &__down {
            margin-right: 5px;

            &:after {
              content: "↑";
              position: absolute;
              top: 0;
              left: calc(100% + 2px);
              line-height: 1.6rem;
              font-weight: 100;
              display: none;
            }
          }

          &__up:after {
            display: inline-block;
          }


          &__down:after {
            transform: rotate(180deg);
            line-height: 1.2rem;
            display: inline-block;
          }
        }
      }

      &_text {
        margin-left: 20px;
        font-size: 14px;
        line-height: 16px;
      }
    }

    &__statDashboardTwo {
      width: 100%;
    }

    &__budgetReport {
      .select-rt__value-container {
        flex-wrap: wrap !important;

        .select-rt__multi-value {
          margin: 0 5px 5px 0;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .cfo-select__container.input-title__container {
        width: 100%;
      }
    }

    &__rolesReport {
      .select-rt__value-container {
        flex-wrap: wrap !important;

        .select-rt__multi-value {
          margin: 0 5px 5px 0;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .col-xl-7 {
        max-height: 500px;

        #center-container {
          flex-direction: column !important;
        }

        .dx-c-bs4-container {
          padding: 0;
          padding-bottom: 10px;

          .dashboard__chart-roles__container {
            width: 100%;
          }
        }
      }

      .dashboard-role__list__no-select__container {
        margin-top: 10px;
      }
    }

    &__projectWorkList {
      .dashboard__card-union > .dashboard-new + .dashboard-new {
        margin-top: -30px;
      }

      &_checkboxMobile {
        margin: 0 0 14px !important;
      }

      .projectWorkList_project {
        box-sizing: border-box;
        width: 100%;

        &:first-child {
          .projectWorkList_project {
            &__wrapper {
              border-radius: 8px 8px 0 0;
            }
          }
        }

        &:last-child {
          .projectWorkList_project {
            &__wrapper {
              border-radius: 0 0 8px 8px;
              border-bottom: 1px solid #F4F4F5;
            }
          }
        }

        &__wrapper {
          display: flex;
          justify-items: flex-start;
          align-items: flex-start;
          border: 1px solid #F4F4F5;
          border-bottom: none;
          box-sizing: border-box;
          width: 100%;
          padding: 20px 15px;
          border-radius: 0;

          &-checked {
            background-color: #F4F4F5;
          }
        }

        &-open {
          .projectWorkList_project {
            &__wrapper {
              border-bottom-right-radius: 8px !important;
              border-bottom-left-radius: 8px !important;
              border-bottom: 1px solid #F4F4F5;
            }
          }
        }

        &__checkbox {
          margin: 0;
        }

        &__content {
          margin-left: 15px;
          width: 100%;
        }

        &__title {
          padding-right: 25px;
          margin-bottom: 8px;
          position: relative;
        }

        &__type {
          font-size: 12px;
          line-height: 16px;
        }

        &__name {
          font-size: 14px;
          line-height: 20px;
        }

        &__icon {
          position: absolute;
          top: 0;
          right: 0;
        }

        &__stage,
        &__leader {
          font-size: 14px;
          line-height: 16px;
        }

        &__stage {
          margin-bottom: 4px;
        }

        &__status {
          margin-top: 8px;

          &-title {
            margin-left: 9px;
            font-size: 12px;
            line-height: 16px;
          }
        }

        &__date {
          margin-top: 8px;
        }

        &__children {
          margin: 20px 0;

          & + .projectWorkList_project {
            .projectWorkList_project__wrapper {
              border-top-right-radius: 8px !important;
              border-top-left-radius: 8px !important;
            }
          }
        }
      }
    }

    &__projectStatusReport {
      .dashboard-status__container {
        flex-direction: column;
        align-items: center;

        .dashboard-status__item {
          width: 100%;
          padding: 10px 0 17px;
          border-bottom: 1px solid #F4F4F5;

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            padding-bottom: 0;
            border-bottom: none;
          }
        }

        .dashboard-status__item + .dashboard-status__item {
          border-left: none;
        }
      }
    }

    &__segmentReport {
      .card-body {
        padding-right: 30px !important;
      }

      .dashboard__chart__container {
        padding: 0;
      }

      .dashboard__bar-title__item.rotated > div {
        transform: translate(-30%, -50%);
      }
    }
  }
}

@media (max-width: 400px) {
  .dashboard {
    &__filter {

      .input-title__label,
      .dashboard__switch-title {
        font-size: 13px;
      }
    }

    &__tooltipMobileShow {
      &_text {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .dx-c-bs4-container {
      padding: 10px 0;
    }

    .dx-c-bs4-label {
      font-size: 11px;
    }

    &__projectWorkList {
      .projectWorkList_project {
        &__wrapper {
          padding: 12px 9px;
        }

        &__content {
          margin-left: 9px;
        }

        &__type {
          font-size: 10px;
          line-height: 12px;
        }

        &__name {
          font-size: 13px;
          line-height: 20px;
        }

        &__stage,
        &__leader {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }

    &__segmentReport {
      .card-body {
        padding-right: 25px !important;
      }

      .dashboard__bar-title__item.rotated > div {
        transform: translate(-90%, -50%);
      }
    }
  }
}

.dashboard-new.card.mini {
  .dashboard__card-title__container {
    margin: 1.25rem 40px 12px;
  }

  .card-title + .card-body {
    padding-bottom: 1.25rem;
  }
}

.dashboard__card-title__exportXls {
  display: flex;
  align-items: center;
}

.font-size-20 {
  font-size: 20px !important;
}