@import "font";
@import "custom/variables";
@import "~bootstrap/scss/bootstrap";
@import "base/variables";
@import "base/mixins";
@import "base/header";
@import "base/input";
@import "../../components/ReactModernCalendarDatepicker/src/DatePicker.css";
@import "base/accordion";
@import "base/status_indicator";
@import "base/tables";
@import "base/auth";
@import "base/briefcase";
@import "base/loader";
@import "base/consolidateReport";
@import "base/createProject";
@import "base/sidebar";
@import "base/navbar";
@import "base/card";
@import "base/todos";
@import "base/checkbox-radio-switch";
@import "base/footer";
@import "base/buttons";
@import "base/pagination";
@import "base/transitoin";
@import "base/form";
@import "base/datepicker";
@import "custom/userAccount";
@import "custom/notify";
@import "custom/pendingAgrees";
@import "custom/status";
@import "custom/dashboard";
@import "custom/dashboard2";
@import "custom/range";
@import "custom/calendar";
@import "custom/popover";
@import "custom/print";
@import "custom/indianaDragScroll";
@import "custom/team";
@import "custom/mvo";
@import "custom/block";
@import "custom/gantt";
@import "custom/command";
@import "custom/budget";
@import "custom/select";
@import "custom/committee";
@import "custom/popper";

:root {
  --primary-color: #{$primary-color};
  --primary-color-rgb: #{$primary-color-rgb};
  --pc: #{$primary-color};
}

html,
body {
  font-size: 1rem;
  font-family: "GolosText";
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-feature-settings: "tnum" on, "lnum" on;
  position: fixed;
  overflow: hidden;

  .inner-body {
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.checkbox,
.radio {
  margin-bottom: 0;
}

a.rt-link {
  color: var(--primary-color);
}

.max-width-1000px {
  max-width: 1000px;
}

.width48 {
  width: 48%;
}

.width100 {
  width: 100%;
}

.width110 {
  width: 110%;
}

.width115 {
  width: 115%;
}

.width120 {
  width: 120%;
}

.width125 {
  width: 125%;
}

.width130 {
  width: 130%;
}

.width135 {
  width: 135%;
}

.width140 {
  width: 140%;
}

.width145 {
  width: 145%;
}

.width150 {
  width: 150%;
}

.width200 {
  width: 200%;
}

.width100px {
  width: 100px;
}

.width200px {
  width: 200px;
}

.width300px {
  width: 300px;
}

.width400px {
  width: 400px;
}

.width500px {
  width: 500px;
}

.width600px {
  width: 600px;
}

.width700px {
  width: 700px;
}

.width800px {
  width: 800px;
}

.width900px {
  width: 900px;
}

.width1000px {
  width: 1000px;
}

// отступы
.margin_b60 {
  margin-bottom: 60px;
}

.marginLeft-12 {
  margin-left: 12px;
}

.marginLeft-3 {
  margin-left: 3px;
}

.marginLeft-5 {
  margin-left: 5px;
}

.marginLeft-20 {
  margin-left: 20px;
}

.marginLeft-30 {
  margin-left: 30px;
}

.marginLeft-2rem {
  margin-left: 2rem;
}

.marginLeft-3rem {
  margin-left: 3rem;
}

.marginTop-32 {
  margin-top: 32px;
}

.marginRight-12 {
  margin-right: 12px;
}

.marginRight-6 {
  margin-right: 6px;
}

.marginTop-5 {
  margin-top: 5px;
}

.marginTop-10 {
  margin-top: 10px;
}

.marginTop-20 {
  margin-top: 20px;
}

.marginBottom-20 {
  margin-bottom: 20px;
}

.marginBottom-50 {
  margin-bottom: 50px;
}

.marginBottom-0 {
  margin-bottom: 0 !important;
}

.marginBottomNegative-05rem {
  margin-bottom: -0.2rem !important;
}

.marginBottom-5 {
  margin-bottom: 5px !important;
}

.marginTop-0 {
  margin-top: 0 !important;
}

.marginBottom-30 {
  margin-bottom: 30px;
}

.paddingTop-15 {
  padding-top: 15px;
}

.padding-8 {
  padding: 8px;
}

.paddingLeft-0 {
  padding-left: 0;
}

.paddingLeft-0-imp {
  padding-left: 0 !important;
}

.paddingRight-0 {
  padding-right: 0;
}

// цвета текста
.primary-color {
  color: var(--primary-color);
}

.text-master-color {
  color: #101828;
}

.text-master-color:hover,
li.active .text-master-color {
  color: var(--primary-color);

  & svg path {
    fill: var(--primary-color);
  }
}

// флексы

.flex-grow {
  flex-grow: 1;
  width: 45%;
}

.row-flex {
  display: flex;
  flex-flow: row wrap;
}

.display-flex-align-items-center {
  display: flex;
  align-items: center;
}

.display-flex {
  display: flex;
  // flex-grow: 1;
  margin-bottom: 12px;
  // width: 100%;
}

.line-height-3-rem {
  line-height: 3rem;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-14-imp {
  font-size: 14px !important;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-10-imp {
  font-size: 10px !important;
}

.font-size-8-imp {
  font-size: 8px !important;
}

.display-flex-direction-row {
  display: flex;
  flex-direction: row;
}

.display-flex-direction-column {
  display: flex;
  flex-direction: column;
}

.flex-inline-center-vertical {
  display: inline-flex;
  align-items: center;
}

.flex-inline-center-filter {
  display: inline-flex;
  align-items: center;
}

.flex-center-vertical {
  display: flex;
  align-items: center;
}

.flex-center {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.flex-col-space-between {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 48%;
}

.flex-space-between {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: center;
}

.flex-start {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.flex-end {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}

.h1-felix {
  font-weight: bold;
  font-size: 28px;
  margin: 20px 0;
}

.h3-felix {
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
  margin: 0 0 20px 0;
  max-width: 470px;
}

.h3 {
  font-size: 22px;
  line-height: 24px;
}

.h4-felix {
  font-weight: bold;
  font-size: 20px;
  margin: 0 0 10px 0;
  max-width: 470px;
}

.h5-felix {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin: 20px 0;
  max-width: 470px;
}

.h5-felix {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin: 20px 0;
  max-width: 470px;
}

.h5-felix-medium {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

// блок контента
.content {
  background-color: $sidebar-bg;
  padding: 0 52px;
  min-height: calc(100vh - 60px);
  padding-bottom: 1rem;
}

.signOut:hover {
  cursor: pointer;
}

hr {
  margin: 15px 0;
  opacity: 1;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: transparent;
}

input {
  outline: none !important;
}

input.inputField {
  background: transparent;
  border: none;
  width: 100%;
  padding: 0;
  line-height: 1em;

  &.timePicker:not(:focus) {
    color: transparent;
  }

  &.inverted {
    text-align: right;
  }

  &.withEditIcon {
    width: calc(100% - 20px);

    &.inverted {
      margin-left: 20px;
    }
  }
}

input.inputField::-ms-clear {
  width: 0;
  height: 0;
}

.rc-select-arrow .rc-select-arrow-icon {
  border: none;
  width: 8px;
  height: 4px;
  position: absolute;
  top: 46%;
  right: 16px;
  left: auto;
  margin: 0;
  // background: url(./images/down.svg) center center;
}

.link_card {
  width: 100%;
  height: 100%;
}

.pointer {
  cursor: pointer;
}

.rc-select-open .rc-select-arrow .rc-select-arrow-icon {
  transform: rotate(180deg);
}

.rc-select-dropdown {
  background: #ffffff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-top: 0px;
  z-index: 3;
}

.rc-select-dropdown::before {
  content: "";
  position: absolute;
  height: 8px;
  top: -8px;
  left: 0;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
}

.rc-select-open .rc-select-selection {
  z-index: 4;
}

.rc-select-selection__choice__content {
  padding-left: 10px;
}

body .menuFirstItem {
  margin-left: -25px;
  padding-left: 25px;
}

body .ocWidget__bar-wrapper {
  display: none;
}

.ReactModalPortal {
  z-index: 1000;
  position: relative;
}

.ReactModal__Overlay {
  overflow-y: scroll;
}

.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

ol,
ul {
  list-style: none;
}

.loginPromt {
  font-size: 12px;
  line-height: 16px;
  color: rgba(16, 24, 40, 0.5);
  padding: 0px 0px 20px 16px;
}

a.active {
  color: var(--primary-color);

  svg {
    path {
      fill: var(--primary-color);
    }
  }

  svg.stroke {
    path {
      fill: none;
      stroke: var(--primary-color);
    }
  }
}

a:hover {
  cursor: pointer;
  color: var(--primary-color);
}

.signOut:hover svg,
a:hover svg {
  path {
    fill: var(--primary-color);
  }

  &.stroke {
    path {
      fill: none;
      stroke: var(--primary-color);
    }
  }
}

.diff-date-show {
  width: 100%;
  position: relative;
  height: 48px;
  background: #f1f4f6;
  border-radius: 8px;
  padding: 4px 40px 8px 16px;
  position: relative;
  overflow: hidden;
  outline: none;
}

.width28 {
  width: 28%;
}

.width68 {
  width: 68%;
}

.modal-new-line .h5-felix-medium {
  white-space: pre-line;
}

.new-line {
  white-space: pre-line;
}

.doc-files .tab-title-felix {
  font-size: 16px;
  line-height: 3rem;
}

.doc-files .tab-data-felix {
  font-size: 14px;
}

.passport-info__title {
  font-size: 22px;
  line-height: 24px;
}

.passport-info__section {
  margin-bottom: 15px;
}

.passport-info__title-section {
  font-size: 16px;
  line-height: 24px;
  font-feature-settings: "tnum" on, "lnum" on;
  font-weight: bold;
}

.passport-info {
  max-width: 850px;

  .accordion-item-felix .tab-title-felix {
    line-height: 2rem;
  }

  .accordion-item-felix.remove .tab-title-felix,
  .accordion-item-felix-open.remove .tab-title-felix {
    text-decoration: line-through;
  }
}

@media (max-width: 1000px) {
  .passport-info {
    width: 99%;
  }
}

.passport-info__table-cell_title,
.passport-info__table-cell_data {
  font-size: 14px;
  line-height: 20px;
}

.passport-info__table-cell_data {
  width: 300px;
  vertical-align: top;
  padding-right: 1rem;
}

.passport-info__table-cell_title {
  width: 250px;
  vertical-align: top;
  padding-right: 1rem;
}

.passport-info__table-cell_data.first.different {
  text-decoration: line-through;
}

.passport-info__table-cell_data.second.different {
  color: #ff5e2d;
}

.passport-info__table-cell_data.second.append {
  color: #00d359;
}

.agreement-container {
  display: flex;
}

.agreement-content {
  flex-grow: 1;
}

.agreement-history {
  width: 300px;
}

.agreement-history__title {
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  margin-top: 24px;
}

.agreement-history__item__container {
  margin-bottom: 1rem;
}

.agreement-history__item__info {
  font-size: 12px;
  /* line-height: 14px; */
  /* letter-spacing: 0.5px; */
  margin-bottom: 0.4rem;
}

.agreement-history__item-date {
  font-size: 13px;
  margin-bottom: 0.6rem;
}

.agreement-history__item-user {
  font-size: 14px;
  line-height: 20px;
}

.agreement-history__item-comment {
  font-size: 12px;
  line-height: 16px;
}

.card.card-briefcase.disabled {
  background: rgba(16, 24, 40, .05);

  .project__status-item:not(.status) {
    background: white;
  }
}

.project__committee-stage-item {
  font-size: 10px;
  background-color: #e8e8ee;
  color: #101828;
  padding: 2px 4px;
  line-height: initial;
  border-radius: 4px;
  margin-right: 0.4rem;
  white-space: normal;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.committee-agree-stage {
    &.finished {
      background-color: #00d359;
      color: white;
    }

    &.active {
      background-color: #ffb608;
      color: white;
    }

    &.reject {
      background-color: #ff4747;
      color: white;
    }
  }
}

.project__status-item {
  font-size: 10px;
  background-color: #e8e8ee;
  color: #101828;
  display: inline-block;
  padding: 2px 4px;
  line-height: initial;
  border-radius: 4px;
  margin-right: 0.4rem;

  &.big-font {
    font-size: 12px;
  }

  //&:nth-child(n + 2) {
  //  margin-left: 0.4rem;
  //}

  &.green {
    background-color: #00d359;
    color: white;
  }

  &.purple {
    background-color: var(--primary-color);
    color: white;
  }

  &.PROJECT_INVESTMENT_COMMITTEE,
  &.STEERING_COMMITTEE {
    &.ACTIVE {
      background-color: hsl(255deg 100% 64%);
      color: white;
    }
  }

  &.status {
    &.RELEASE {
      background-color: #00d359;
      color: white;
    }

    &.COORDINATION {
      background-color: #ffb608;
      color: white;
    }

    &.REWORK {
      background-color: #ff4747;
      color: white;
    }

    &.EDITING,
    &.DRAFT {
      background-color: #40a2ff;
      color: white;
    }

    &.TECHNICAL_PROJECT {
      background-color: #4A6DFF;
      color: white;
    }

    &.RUZ_COMMON_PROJECT {
      background-color: #cd0000;
      color: rgb(255, 215, 0);
    }
  }

  &.status_committee {
    &.ACTIVE {
      background-color: #00d359;
      color: white;
    }

    &.VOTE {
      background-color: #ffb608;
      color: white;
    }

    &.COMPLETED {
      background-color: #ff4747;
      color: white;
    }
  }

  &.reportCloseGrade {
    &.THAN_95 {
      background-color: #00d359;
      color: white;
    }
  }

  &.businessCaseStatus {

    &.RECEIVED,
    &.RECEIVED_IN_GREATER_VOLUME {
      color: white;
      background-color: #0ACB5B;
    }

    &.NOT_RECEIVED {
      color: white;
      background-color: #FF2626;
    }

    &.RECEIVED_IN_SMALLER_VOLUME {
      color: white;
      background-color: #FDA610;
    }
  }

  &.riskPriority {
    &.HIGH {
      color: white;
      background-color: #FF2626;
    }

    &.AVERAGE {
      color: white;
      background-color: #FDA610;
    }

    &.LOW {
      color: white;
      background-color: #0ACB5B;
    }

    &.NONE {
      color: white;
      background-color: #0ACB5B;
    }
  }

  &.riskPriorityAlter {
    color: white;

    &.HIGH {
      background-color: red;
    }

    &.AVERAGE {
      background-color: #d8c700;
    }

    &.LOW {
      background-color: green;
    }
  }

  &.reportCloseGrade {
    &.THAN_95 {
      color: white;
      background-color: #0ACB5B;
    }

    &.BETWEEN_85_95 {
      color: white;
      background-color: #FDA610;
    }

    &.LESS_85 {
      color: white;
      background-color: #FF2626;
    }
  }

}

.spoiler-list__container {

  .accordion-item-felix,
  .accordion-item-felix-open {
    margin-bottom: 1rem;
  }
}

.project-back__container {
  display: flex;

  .project-back__button {
    flex-grow: 1;
  }

  .project-back__to-edit {
    border: none;
    margin: auto;
    font-size: 12px;
  }
}

.project-back__go-edit__container {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.project-back__go-edit__icon {
  transform: rotate(180deg);
}

.data-picker-double__container {
  margin: 0 -0.5rem;

  .react-datepicker-wrapper {
    margin: 0 0.5rem;
  }
}

.input.rt {
  margin-bottom: 24px;

  &.noFocus .label {
    font-size: 16px;
    font-weight: 100;
    margin-top: 10px;
  }
}

.inputSelect.rt {
  overflow: initial;
}

.select-rt {
  width: 100%;

  .select-rt__control {
    border: none;
    background: none;
    min-height: inherit;
  }

  .select-rt__value-container {
    padding: 0;

    .select-option__description {
      display: none;
    }
  }

  .select-rt__single-value {
    margin: 0;
  }

  .select-rt__menu {
    margin: 0;
    margin-top: 2px;
    width: calc(100% + 2rem);
    margin-left: -16px;
    padding: 0;
  }

  .select-rt__control {
    box-shadow: none !important;
  }

  .select-rt__input {
    margin-left: -2px;
  }

  .select-rt__multi-value__label {
    padding: 0px 5px;
  }

  .select-rt__multi-value {
    margin: 0 2px;
  }

  .select-rt__indicator {
    padding-top: 0;
    padding-bottom: 0;
  }

  .select-rt__indicators {
    margin-top: -15px;
    margin-right: -15px;
  }

  .select-rt__single-value--is-disabled {
    color: inherit;
  }

  &.select-multiline {
    .select-rt__single-value {
      white-space: initial;
      position: initial;
      transform: inherit;
    }
  }
}

.search .input-title__container-main {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNy41IDExQzE3LjUgMTQuNTg5OSAxNC41ODk5IDE3LjUgMTEgMTcuNUM3LjQxMDE1IDE3LjUgNC41IDE0LjU4OTkgNC41IDExQzQuNSA3LjQxMDE1IDcuNDEwMTUgNC41IDExIDQuNUMxNC41ODk5IDQuNSAxNy41IDcuNDEwMTUgMTcuNSAxMVpNMTYuMTAxOCAxNy4xNjI0QzE0LjcxNyAxOC4zMTAxIDEyLjkzOTEgMTkgMTEgMTlDNi41ODE3MiAxOSAzIDE1LjQxODMgMyAxMUMzIDYuNTgxNzIgNi41ODE3MiAzIDExIDNDMTUuNDE4MyAzIDE5IDYuNTgxNzIgMTkgMTFDMTkgMTIuOTM5MSAxOC4zMTAxIDE0LjcxNyAxNy4xNjI0IDE2LjEwMThMMjAuOTk5MSAxOS45Mzg0TDE5LjkzODQgMjAuOTk5MUwxNi4xMDE4IDE3LjE2MjRaIiBmaWxsPSIjMTAxODI4Ii8+Cjwvc3ZnPgo=) rgba(16, 24, 40, 0.05) no-repeat 96% center;
  background-position-x: calc(100% - 1rem);
}

.input-title__container {
  .input-title__label {
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 2rem);
    text-overflow: ellipsis;
  }
}

.input-select-link {
  z-index: 1;
  position: absolute;
  margin-top: 22px;
  margin-left: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 430px;
  white-space: nowrap;

  &.disabled {
    color: rgb(84, 84, 84);
  }

  &:hover {
    color: var(--primary-color) !important;
    cursor: pointer;
  }
}

.input-title__container.input-date,
.input-title__container.input-select {
  .input-title__label {
    padding-right: 2rem;

    &.with-tooltip {
      padding-right: 3rem;
    }
  }

  .input-title__tooltip {
    right: 3rem;

    &.with-clearable {
      right: 4.5rem;
    }
  }

  &.with-hide-icon {
    .input-title__tooltip {
      right: 1rem;

      &.with-clearable {
        right: 2.1rem;
      }
    }
  }

  &.with-hide-title {
    .input-title__label {
      padding: 0;
    }

    .input-title__block {
      padding: 0;

      .select-rt__clear-indicator {
        margin-top: 8px;
      }
    }
  }
}

.input-title__container.input-select-tree {
  .input-title__label {
    padding-right: 2rem;

    &.with-tooltip {
      padding-right: 3rem;
    }
  }

  .input-title__tooltip {
    right: 3rem;

    &.with-clearable {
      right: 4.5rem;
    }
  }
}

.input-title__container.input-select-tree .rc-tree-select-selection-item {
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  padding-right: 5rem;
  text-overflow: ellipsis;
}

.input-title__container.input-textarea-tooltip {
  .input-title__tooltip {
    padding-top: 0.8rem;
    align-items: flex-start;

    &.with-clearable {
      right: 2.2rem;
    }
  }
}

.input-title__container.input-textarea {
  .input-title__label {
    white-space: normal;
  }

  .input-title__block {
    padding: 0;
  }

  .input-title__label {
    position: relative;
  }
}

.input-title__container.dashboard__switch {
  .input-title__description {
    padding-left: 0;
    margin-left: 52px;
  }
}

.input-title__container-main {
  background-color: #f1f4f6;
  border: 1px solid #f1f4f6;
  border-radius: 8px;
  padding: 5px 1rem;
  position: relative;

  .input-title__tooltip {
    &.with-clearable {
      top: 0.1rem;
      right: 2.2rem;
    }
  }
}

.input-title__container {
  margin-bottom: 1rem;

  &.error .input-title__container-main {
    border-color: red;
  }
}

.input-title__container.focus .input-title__label {
  font-size: 12px;
  padding-top: 0;
  font-weight: bold;

  &.hide {
    font-size: 16px;
  }
}

.input-title__container.focus.with-hide-title .input-title__label {
  display: none;
}

.input-title__label {
  position: absolute;
  font-size: 16px;
  color: #868b95;
  padding-top: 0.5rem;
  transition: 0.1s;
  cursor: default;

  &.with-tooltip {
    padding-right: 4rem;
  }

  &.hide {
    opacity: 0;
    position: relative;
    z-index: -1;
    height: 0px;
    padding: 0;
  }
}

.small-input-title {
  .input-title__label {
    font-size: 14px;
  }
}

.input-title__tooltip {
  z-index: 10;
  position: absolute;
  overflow: visible;
  max-width: 100%;
  right: 1rem;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.input-title__description {
  font-size: 12px;
  line-height: 16px;
  color: rgba(16, 24, 40, 0.5);
  padding-left: 1rem;
  padding-top: 2px;

  &.displaced {
    margin-bottom: 16px;
    margin-top: -16px;
  }

  &.no-left-padding {
    padding-left: 0;
  }
}

.doc-required-description {
  margin-top: -18px;
  margin-bottom: 1rem;
}

.input-title__in-title-description {
  font-size: 12px;
  line-height: 16px;
  color: rgba(16, 24, 40, 0.5);
  padding-left: 1rem;
  padding-top: 2px;
  position: absolute;
  top: 25px;
  right: 1rem;
}

.select-option__description {
  font-size: 12px;
  line-height: 16px;
  color: rgba(16, 24, 40, 0.5);
  padding-top: 2px;
}

.input-title__block {
  padding: 1rem 0 0;

  &.with-tooltip {
    div[class*='value-container']{
      padding-right: 1.5rem;
    }

    &.with-clearable {
      div[class*='value-container']{
        padding-right: 2.5rem;
      }
    }
  }
}

.input-title__edit-tooltip {
  position: absolute;
  top: 0;
  bottom: 0;
  right: calc(0.1em - 2px);
  z-index: 10;
  overflow: visible;
  max-width: 100%;
  display: flex;
  align-items: center;

  &.inverted {
    right: unset;
    left: calc(0.1em - 2px);
  }
}

textarea.input-rt {
  margin: 0px;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  resize: vertical;
  min-height: 4rem;
  overflow: hidden;

  &.with-tooltip {
    padding-right: 1rem;
  }

  &.min-height-1 {
    min-height: 1rem;
  }
}

.color-indicator-text {
  margin-right: 12px;
  display: inline-block;

  & + .color-indicator {
    margin-right: 5px;
  }
}

.color-indicator {
  color: red;
  background-color: gray;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  display: inline-block;
  margin-right: 5px;
  margin-left: 4px;
  transform: scale(2);

  &.text-line {
    margin-bottom: 2px;
  }

  &.HIGH {
    background-color: red;
  }

  &.AVERAGE {
    background-color: #d8c700;
  }

  &.LOW {
    background-color: orange;
  }

  &.NONE {
    background-color: green;
  }

  &.status-report {
    &.PLAN {
      background-color: green;
    }

    &.SLIGHT_DEVIATION {
      background-color: orange;
    }

    &.SIGNIFICANT_DEVIATION {
      background-color: red;
    }
  }

  &.risk-indicator {
    margin-left: 8px;

    &.LOW {
      background-color: green;
    }
  }

  &.mini {
    transform: none;
  }

  &.with-text-right {
    margin-right: 5px;
  }

  &.work-indicator {
    &.PLAN {
      background-color: #acacac;
    }

    &.WORK {
      background-color: #6f99c3;
    }

    &.SUCCESS {
      background-color: #41c151;
    }

    &.CANCEL {
      background-color: hsl(210deg 16% 84%);
    }

    &.RED {
      background-color: red;
    }

    &.GREEN {
      background-color: green;
    }

    &.YELLOW {
      background-color: orange;
    }
  }

  &.red {
    background-color: red;
  }

  &.green {
    background-color: green;
  }

  &.yellow {
    background-color: orange;
  }

  &.transparent {
    background-color: transparent;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes progressBar {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes scaleFinished {
  0% {
    transform: scale(10);
    opacity: 0;
  }

  50% {
    transform: scale(0.2);
  }

  70% {
    transform: scale(1.2);
  }

  90% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

body::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
}

.margin-left-multiple + .margin-left-multiple,
.rt-felix-button__container + .rt-felix-button__container {
  margin-left: 0.7em;
}

.rt-felix-button__container {
  outline: none !important;
  background: var(--primary-color);
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  border: 2px solid transparent;

  &.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }

  .rt-range-title__tooltip {
    position: absolute;
    top: -3px;
    right: 2px;
  }

  .rt-range-title__tooltip path {
    fill: white;
    fill-opacity: 1;
  }

  .rt-felix-button__title {
    white-space: nowrap;
  }

  &.text {
    border: none;
    border-radius: 0;
    color: var(--primary-color);
    background: transparent;
    padding: 0;

    &.disabled {
      color: #cccccc;
    }
  }

  &.danger {
    background: #ff4747;
  }

  &.outline {
    border: 2px solid var(--primary-color);
    background: white;
    color: #101828;

    &.red {
      border-color: red;
    }

    &.danger {
      border-color: #ff4747;
    }

    .rt-felix-button.loading .rt-felix__background-img {
      @include button-loader('%234758fb', !important);
    }

    .rt-felix-button.finished .rt-felix__background-img {
      @include button-finished('%234758fb', !important);
    }

    .rt-felix-button.error .rt-felix__background-img {
      @include button-error('%234758fb', !important);
    }
  }

  &.mini {
    padding: 0.3em 1em;
    font-size: 0.8em;

    .rt-felix__background-img {
      margin-top: -2px;
    }

    .rt-felix-button.loading .rt-felix__background-img {
      margin-top: 2px;
    }
  }
}

a.rt-felix-button__container {
  padding: 0.5rem 1rem;
}

a.rt-felix-button__container:hover,
a.rt-felix-button__container:focus {
  color: white;
  // box-shadow: 0px 2px 5px 0px rgb(0 0 0 / 30%);
}

a.rt-felix-button__container.outline:hover,
a.rt-felix-button__container.outline:focus {
  color: #101828;
}

a.rt-felix-button__container.fix-line-height {
  line-height: 46px;
}

.rt-felix-button__container:not(.text):not(.animation-disable) {
  &.loading::before {
    position: absolute;
    content: "";
    bottom: 0px;
    left: 0px;
    transform: translateX(-100%);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.07);
    animation: 3000ms linear 0s infinite normal none running progressBar;
  }

  .rt-felix-button__title {
    transition: 0.1s;
  }

  &.loading .rt-felix-button__title,
  &.finished .rt-felix-button__title,
  &.error .rt-felix-button__title {
    opacity: 0;
  }

  .rt-felix-button {
    position: absolute;
    display: none;
    left: 50%;
    transform: translate(-50%);
  }

  &:not(.loading, .error, .finished)[disabled] {
    background: #cecece;
  }

  &.loading .rt-felix-button.loading,
  &.finished .rt-felix-button.finished,
  &.error .rt-felix-button.error {
    display: inline-block;
  }

  .rt-felix-button.loading .rt-felix__background-img {
    @include button-loader('white');
    width: 14px;
    height: 14px;
    margin-top: 4px;
    display: inline-block;
    background-size: cover;
    animation-duration: 800ms;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: spin;
  }

  .rt-felix-button.finished .rt-felix__background-img {
    @include button-finished('white');
    height: 23px;
    width: 23px;
    display: inline-block;
    background-size: cover;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: scaleFinished;
  }

  .rt-felix-button.error .rt-felix__background-img {
    @include button-error('white');
    height: 14px;
    width: 14px;
    display: inline-block;
    background-size: cover;
    margin-top: 3px;
    animation-duration: 0.5s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: scaleFinished;
  }
}

.nowrap {
  white-space: nowrap;
}

.break-word {
  word-wrap: break-word;
}

.word-break-break-word {
  word-wrap: break-word;
  word-break: break-word;
}

.break-all {
  overflow-wrap: break-word;
  word-break: break-all;
}

.budget-table__container {
  max-width: 75%;

  .input-edit-felix {
    padding: 0;
  }
}

.oibda-table__wrapper {
  margin-top: 10px;
}

.budget-table__wrapper {
  margin-bottom: 1rem;
  //overflow: auto;
}

.indiana-scroll-container,
.custom-scrollbar {
  &::-webkit-scrollbar {
    height: 7px;
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(16, 24, 40, 0.1);
    border-radius: 1px;
  }

  &::-webkit-scrollbar-thumb {
    background: #b5b7c0;
    border-radius: 1px;
  }
}

.custom-width-scrollbar {
  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
}

.tab-data-felix {
  .budget-table__wrapper {
    margin-top: 10px;
  }
}

.budget-table__finance {
  min-width: 800px;

  &.prediction {
    min-width: 900px;

    .table-mini-select {
      width: 120px;
    }
  }
}

.budget-table__field-head {
  width: 7em;
  max-width: 13em;
  white-space: nowrap;
}

.budget-table__head {
  border-bottom: 1px solid #eaeaea;
}

.table.table-felix.budget-table__container tbody > tr > td.left-border,
.table.table-felix.budget-table__container thead > tr > td.left-border {
  border-left: 1px solid #eaeaea;
}

.table-felix tr td:first-child.padding-left-8 {
  padding-left: 8px !important;
}

.table.table-felix.budget-table__container thead > tr > td,
.table.table-felix.budget-table__container tbody > tr > td {
  padding: 2px 11px;
  vertical-align: middle;
}

.budget-table__button-add {
  flex-grow: 1;
}

.edit-button-felix {
  color: var(--primary-color);
  cursor: pointer;

  &.disabled {
    background-color: transparent;
    color: gray;
  }
}

.edit-button-felix a {
  color: inherit;
}

.agreement-page .agreement-history {
  width: initial;
}

.title-program {
  font-size: 22px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #101828;
  font-weight: 400;
}

.program-list {
  list-style: disc;
  padding-left: 18px;

  .program-list__item {
    text-transform: capitalize;
    color: var(--primary-color);
    margin-bottom: 1rem;
  }
}

.children-budget__container {
  .children-budget__year {
    font-weight: initial;
    padding: 0 8px !important;
  }

  &.table.table-felix.budget-table__container thead > tr > td,
  &.table.table-felix.budget-table__container tbody > tr > td {
    padding: 8px;
  }

  .budget-table__field-head.left-border,
  tbody > tr > td.left-border {
    border-left: 1px solid #eaeaea;
  }
}

.alert.alert-light {
  background-color: #f1f4f6;
  border: 1px solid hsl(204, 22%, 93%);
  border-radius: 8px;
  font-size: 13px;
  padding: 8px 12px;
  color: #333;
  font-weight: 100;
  white-space: break-spaces;
}

.nav-felix {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: inherit;
}

.admin-nav__container {
  display: flex;
  margin-top: 30px;

  .pageSelector__wrapper {
    width: 100%;
  }

  .pageSelectorItem {
    white-space: nowrap;
  }
}

.admin-nav__links {
  flex-grow: 1;
  min-height: 45px;
}

.admin-nav__search {
  border-bottom: 1px solid #e4e9ed;
  padding-bottom: 12px;

  .search-query {
    height: 32px;
    width: 256px;
    font-size: 13px;
  }
}

.user-item__container {
  background: #ffffff;
  border-radius: 8px;
  padding: 40px;
  margin-top: 12px;

  .user-item__line-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .user-item__line-info {
    display: flex;
  }

  .user-item__display-name {
    font-weight: bold;
    font-size: 16px;
  }

  .user-item__change-role__container {
    flex-grow: 2;
    flex-basis: 50%;
  }

  .checkbox.user-item__checkbox {
    padding-left: 18px;

    .checkbox__label {
      font-size: 12px;
      padding-left: 0;

      &::before {
        width: 12px;
        height: 12px;
        top: 4px;
      }

      &::after {
        width: 9px;
        height: 9px;
        background-size: 9px 9px;
        top: 5px;
      }
    }
  }
}

.user-list__container {
  margin-top: 40px;
  font-size: 12px;
}

.admin__container {
  width: 100%;
  background: #f4f4f5;

  .input-title__container-main {
    background-color: rgba(16, 24, 40, 0.05);
  }
}

.change-role-selector__container {
  display: flex;

  .input-title__container {
    margin: 0;
    flex-grow: 1;
  }

  .input-title__container-main {
    padding: 1px 0.8rem;
  }
}

.change-role-selector__select__container {
  width: 75%;
}

.change-role-selector__button__container {
  margin-left: 0.7em !important;
  width: 25%;
}

.agreement-diff__select-version__container {
  display: flex;
  margin-bottom: -1rem;
  max-width: 850px;
  justify-content: flex-end;
}

.agreement-diff__select-version__old {
  width: 35.2%;
  padding-right: 1rem;
}

.agreement-diff__select-version__new {
  width: 35.2%;
  padding-right: 1rem;
}

.notify.badge {
  color: #fff;
  background-color: #ff4f12 !important;
  border-radius: 8px;
  font-size: 10px;
  line-height: 11px;
  font-feature-settings: "tnum" on, "lnum" on;
}

.release.badge {
  color: #fff;
  background-color: var(--primary-color) !important;
  border-radius: 8px;
  font-size: 10px;
  line-height: 11px;
  font-feature-settings: "tnum" on, "lnum" on;
  margin-left: 8px;
}

.margin-between + .margin-between {
  margin-left: 1rem;
}

.margin-between-12 + .margin-between-12 {
  margin-left: 12px;
}

.sidebar-wrapper {
  overflow: auto;
}

.red {
  color: red;
}

.green {
  color: #18cd64;
}

.background-felix {
  background: var(--primary-color) !important;
}

.background-inherit {
  background: inherit;
}

.background-grey {
  background: rgba(16, 24, 40, 0.05);
}

.white-space-nowrap {
  white-space: nowrap;
}

.rt-tooltip__container {
  .tooltip-inner {
    max-width: none;
    border-radius: 16px;
    color: rgba(16, 24, 40, 0.7);
    font-size: 12px;
    line-height: 16px;
    box-shadow: 0px 0px 1px rgba(28, 41, 61, 0.1), 0px 12px 20px rgba(28, 41, 61, 0.1);
    background: #ffffff;
    padding: 16px;
    text-align: left;
  }

  .arrow::before,
  .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
  }

  &.bs-tooltip-top > .arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(0, 0, 0, 0.25);
  }

  &.bs-tooltip-top > .arrow::after {
    bottom: -1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff;
  }

  &.bs-tooltip-right {
    & > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25);
    }

    & > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff;
    }

    & > .arrow {
      margin-top: -3px;
    }
  }
}

.tooltip.fade {
  opacity: 1;
  // filter: drop-shadow(0px 0px 1px rgba(28, 41, 61, 0.1)) drop-shadow(0px 12px 20px rgba(28, 41, 61, 0.1));
}

.marked-title {
  text-transform: capitalize;
  color: var(--primary-color);
  display: list-item;
  list-style: disc;
}

.no-padding {
  padding: 0 !important;
}

.modal-dialog.modal-big {
  max-width: 1300px;
}

.duration.warn {
  color: #cc4c00;
}

.spoiler-item-work {
  margin-left: 1rem;
  display: list-item;
}

.bold {
  font-weight: bold !important;
}

.break-spaces {
  white-space: break-spaces;
}

.input-white {
  .input-title__container-main {
    background: white;
  }
}

.project__status-container {
  display: flex;
  flex-wrap: wrap;

  .project__status-item {
    margin-top: 0.4rem;
  }
}

.next_project_version_link {
  font-size: 12px;
  color: var(--primary-color);
  white-space: nowrap;
}

.link_card.next_project_version_link:not(:hover) {
  svg > path {
    fill: #4a4a4a;
  }
}

.next_project_version_ico {
  margin-left: 5px;

  & > path {
    fill: #4a4a4a;
    transform: scale(0.9);
  }
}

.payback-period__input {
  position: relative;

  .input-title__in-title-description {
    position: absolute;
    top: 25px;
    right: 1rem;

    &.with-tooltip {
      right: 3rem;
    }
  }
}

.pointer-events-none {
  pointer-events: none;
}

.work-group__children {
  margin-left: 1rem;
}

.work-group__item,
tr.work-group__item > td {
  font-weight: bold !important;
}

.progress {
  background-color: #e8e8ee;
  border-radius: 8px;

  &.mini {
    height: 6px;
  }

  .progress-bar {
    background-color: var(--primary-color);
    border-radius: 8px;
  }
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.search-main {
  margin-bottom: 20px;

  .search-query {
    width: 100%;
  }
}

.modal-content {
  width: calc(100% - 40px);
  margin: 0 auto;
}

.docList {
  word-break: break-word;
}

.report-table-wrapper {
  overflow: auto;
}

.report-lesson-table .table-multiline-item {
  margin-bottom: 0.3rem;
}

.table-with-miltiline .table-multiline-item + .table-multiline-item {
  margin-top: 0.3rem;
}

.table-with-border table > tbody > tr > td {
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
}

.one-line-block {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.copy-work__button {
  border: 2px solid var(--primary-color);
  padding: 10px 18px;
  border-radius: 8px;
}

.copy-work__button:hover {
  color: #4a4a4a;
}

.project-append-button__container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .form-group {
    margin-right: 1rem;
  }
}

.employee_add_buttons__container {
  display: flex;
  flex-wrap: wrap;

  .form-group {
    margin-right: 10px;
  }
}

@media (max-width: 1500px) {
  .user-item__change-role__container {
    margin-top: 30px;
  }
}

@media (max-width: 1450px) {
  .agreement-history {
    width: 200px;
  }
}

@media (max-width: 1300px) {
  .agreement-history {
    width: 150px;
  }

  .admin-nav__container {
    flex-direction: column-reverse;
    overflow-x: auto;

    .admin-nav__search {
      border-bottom: none;
      padding: 0;

      .search-query {
        width: 100%;
        height: 48px;
        margin-bottom: 30px;
        font-size: 16px;
        line-height: 24px;
        background-position: bottom 50% right 15px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .agreement-history {
    width: 100%;

    &_spoiler {
      margin-bottom: 15px;
    }
  }

  .passport-info__table-cell_data.second {
    padding-right: 0;
  }

  .agreement-diff__select-version__container {
    margin-right: -50px;
    margin-left: -50px;

    .agreement-diff__select-version__old {
      width: 36%;

      .input-title__block {
        font-size: 13px;
      }
    }

    .agreement-diff__select-version__new {
      width: 37%;

      .input-title__block {
        font-size: 13px;
      }
    }
  }

  .user-item__container {
    padding: 25px 40px 40px;
    position: relative;

    .user-item__line-info {
      flex-wrap: wrap;
    }

    .user-item__line-info__item {
      flex-basis: 50%;
    }

    hr {
      margin: 20px 0;
    }

    .user-item__current-role,
    .user-item__change-role__container,
    .user-item__notification {
      flex-basis: 100%;
    }

    .user-item__current-role,
    .user-item__post,
    .user-item__department,
    .user-item__email,
    .user-item__phone {
      font-size: 12px;
      line-height: 16px;
    }

    .user-item__input {
      margin-top: 15px;
    }

    .user-item__email,
    .user-item__phone {
      margin-top: 15px;
    }

    .user-item__notification {
      display: flex;
    }

    .user-item__checkbox {
      padding-left: 30px !important;
      box-sizing: border-box;

      &:first-child {
        padding-right: 15px;
      }

      .checkbox__label {
        font-size: 12px;
        line-height: 16px;

        &::before {
          width: 20px !important;
          height: 20px !important;
          top: 0 !important;
          border: 1.5px solid rgba(16, 24, 40, 0.25);
          border-radius: 5px;
          box-sizing: border-box;
        }

        &::after {
          width: 20px !important;
          height: 20px !important;
          top: 0 !important;
          background-size: 16px 20px !important;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .saveDoc {
    flex-direction: column;

    .input-file-felix {
      .rt-custom-file-label {
        margin: 0;
        margin-bottom: 20px;
      }
    }
  }

  .admin-nav__container {
    .pageSelectorItem {
      margin-right: 20px;
    }
  }
}

@media (max-width: 1000px) {
  .saveDoc {
    flex-direction: row;

    .input-file-felix {
      .rt-custom-file-label {
        margin-left: 12px;
      }
    }
  }

  .modal {
    z-index: 10050;

    &-dialog {
      width: 80%;
      max-width: 750px;
    }

    &-backdrop {
      z-index: 10040;
    }
  }

  .admin-nav__container {
    .pageSelectorItem {
      margin-right: 3%;
    }
  }
}

@media (max-width: 850px) {
  .saveDoc {
    flex-direction: column;

    .input-file-felix {
      .rt-custom-file-label {
        margin-left: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .content {
    padding: 0 72px 20px 20px;

    .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }

    .h1-felix {
      margin-top: 40px;
    }

    .display-flex {
      flex-grow: 1;
      margin-bottom: 16px;
      flex: 0 0 50%;
      max-width: 50%;
      padding-right: 8px;
      padding-left: 8px;
    }

    .row.row-flex {
      margin-right: -8px;
      margin-left: -8px;
      margin-bottom: -16px;
    }

    .card {
      &-title {
        margin-top: 16px;
        margin-bottom: 5px;
        font-size: 20px;
        line-height: 24px;
      }

      p {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 5px;
      }

      hr {
        margin-top: 4px;
        margin-bottom: 8px;
      }
    }
  }


  .admin__container {
    padding: 28px 72px 40px 20px;
  }

  .input-title__container {
    margin-bottom: 20px;
    font-size: 16px;
  }

  .input-title__container-main {
    padding: 3px 1rem;
  }

  .input-title__container.focus .input-title__label {
    font-weight: normal;
  }

  .rt-felix-button__container,
  a.rt-felix-button__container {
    padding: 14px 40px;
  }

  .file-add-felix {
    .h3-felix {
      font-size: 20px;
      margin-top: 32px;
    }
  }

  .saveDoc {
    flex-direction: row;

    .input-file-felix {
      .rt-custom-file-label {
        margin-left: 12px;
      }
    }
  }

  .list-items__container {
    margin-top: 8px;

    tr {
      font-size: 14px;
      line-height: 20px;

      th {
        padding: 4px 18px 4px 0;
      }

      td {
        padding: 4px;
      }
    }
  }

  .agreement-content {
    width: 100%;
  }

  .modal {
    &-dialog {
      width: 80%;
      max-width: 620px;
    }

    &-content {
      padding: 20px 20px;
      position: relative;
    }

    &-header {
      padding: 0;
      margin-bottom: 5%;
      border-bottom: none;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      .close {
        position: absolute;
        top: 29px;
        right: 29px;
        font-size: 32px;
        line-height: 17px;
        font-weight: normal;
        color: #101828;
      }
    }

    &-title {
      font-size: 24px;
      line-height: 32px;
    }

    &-body {
      padding: 0;
      font-size: 16px;
      line-height: 24px;
    }

    &-footer {
      margin-top: 5%;
      margin-bottom: -2%;
      padding: 0;
      border-top: none;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &-big,
    &-xl {
      width: 100%;

      .modal {
        &-content {
          padding: 60px 20px;
          position: relative;
        }

        &-header {
          margin-bottom: 32px;
        }

        &-footer {
          margin-top: 20px;
          margin-bottom: -10px;
        }
      }
    }
  }

  .admin-nav__container {
    .pageSelector {
      border-bottom-width: 2px;
      width: max-content;

      &__wrapper {
        overflow: auto;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .pageSelectorItem {
      margin-right: 25px;
      transform: translateY(2px);
    }
  }

  .text-master-color {
    &:hover {
      color: #101828;

      & svg path {
        fill: #101828;
      }
    }
  }
}

@media (max-width: 660px) {
  .content {
    padding: 0 20px 20px;
  }

  .admin__container {
    padding-right: 20px;
  }

  .rt-felix-button__container {
    padding: 14px 30px;
  }

  a.rt-felix-button__container {
    padding: 14px 30px;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .content {
    .h1-felix {
      font-size: 18px;
      line-height: 24px;
      margin-top: 20px;
      margin-bottom: 12px;
    }

    .display-flex {
      margin-bottom: 8px;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .row.row-flex {
      margin-bottom: -8px;
    }

    .card {
      &-title {
        font-size: 16px;
        line-height: 20px;
      }

      hr {
        margin-top: 4px;
        margin-bottom: 8px;
      }

      .next_project_version__mobile {
        float: none !important;
        font-size: 13px;
        line-height: 20px;
        display: flex;
        padding-top: 10px;
        border-top: 1px solid #f4f4f5;
        margin-top: 10px;
      }

      .next_project_version_link {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .project {
    .project-header {
      position: relative;

      .h1-felix {
        margin-bottom: 18px;
      }
    }

    .search-block-felix {
      position: static;

      .custom-search-input {
        width: 100%;

        .search-query {
          width: 100%;
        }
      }

      .create-button-mobile-mini {
        position: absolute;
        top: 7px;
        right: 7px;

        .submit {
          padding: 6px 10px;
        }
      }

      .submit {
        margin-bottom: 0;
        background: transparent;
        border: 2px solid var(--primary-color);
      }
    }
  }

  .admin__container {
    padding: 8px 20px 40px;
  }

  .search-main {
    .search-query {
      &::placeholder {
        font-size: 14px;
      }
    }
  }

  .file-add-felix {
    .h3-felix {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 12px;
    }
  }

  .saveDoc {
    flex-direction: column;

    .input-file-felix {
      .rt-custom-file-label {
        margin-left: 0;
        width: 100%;
      }
    }
  }

  .checkbox__label {
    font-size: 14px;
  }

  .list-items__container {
    margin-top: 8px;
  }

  .list-item {
    margin-top: 12px;
    font-size: 14px;
    line-height: 20px;

    &__title {
      font-weight: 700;
    }

    &__text {
      margin-top: 4px;
    }
  }

  .title-program {
    font-size: 18px;
    line-height: 24px;
  }

  .passport-info__section {
    min-width: 400px;
    padding-right: 20px;

    table {
      margin-bottom: 5px;
    }

    .accordion-item-felix {
      margin-bottom: 10px;
    }
  }

  .passport-info__table-cell_title,
  .passport-info__table-cell_data {
    font-size: 13px;
    line-height: 20px;
  }

  .agreement-diff__select-version__container {
    padding-right: 20px;
    margin-right: 0;
    margin-left: 0;
    min-width: 400px;

    .agreement-diff__select-version__old {
      width: 38%;
    }

    .agreement-diff__select-version__new {
      width: 35%;
      padding-right: 0;
    }

    .input-title__container-main {
      position: relative;
    }
  }

  .modal {
    &-dialog {
      width: 100%;
      max-width: 600px;
      margin: 10px 0;
    }

    &-content {
      padding: 20px 20px;
    }

    &-header {
      margin-bottom: 10px;
    }

    &-title {
      font-size: 18px;
      line-height: 24px;
    }

    &-body,
    &-body .h5-felix-medium {
      font-size: 14px;
      line-height: 20px;
    }

    &-footer {
      margin-top: 20px;
      margin-bottom: -10px;

      .rt-felix-button__container,
      .modalButton {
        width: 100%;
      }

      .rt-felix-button__container + .rt-felix-button__container,
      .modalButton + .modalButton {
        margin-top: 10px;
      }
    }
  }

  .modal__buttons {
    width: 100%;

    .margin-left-multiple {
      margin-left: 0;
    }
  }

  .button-group {
    margin-left: 0 !important;
  }

  .admin-nav__container {
    .pageSelector {
      min-height: 35px;
    }

    .pageSelectorItem {
      font-size: 14px;
      line-height: 20px;
      padding-bottom: 0;
    }
  }

  .user-item__container {
    padding: 20px;
    position: relative;

    .user-item__line-info__item {
      flex-basis: 100%;
    }

    .user-item__display-name {
      font-size: 14px;
      line-height: 20px;
    }

    .user-item__change-role__container {
      position: static;
      margin-top: 15px;

      .change-role-selector__container {
        flex-wrap: wrap;

        .input-title__container {
          width: 100%;
        }

        .change-role-selector__select__container {
          width: 100%;
        }

        .change-role-selector__button__container {
          width: 100%;
          margin: 0;
          margin-top: 20px;
        }

        button {
          width: 100%;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .user-item__department,
    .user-item__email,
    .user-item__phone {
      margin-top: 10px;
    }

    .user-item__notification {
      flex-wrap: wrap;
    }

    .user-item__checkbox {
      width: 100%;
      padding-right: 0 !important;

      &:first-child {
        margin-bottom: 10px;
      }
    }
  }

  .admin-system__container {
    .card-title {
      padding: 20px;
      padding-right: 40px;

      &::after {
        right: 20px;
      }
    }

    .card-body {
      border-top: none !important;
      padding: 20px !important;
      padding-top: 0 !important;
    }

    .addOrganizationButton {
      a {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--primary-color);
        border-radius: 8px;
        color: #ffffff;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .organization {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #e8e8ee;

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }

      &_name,
      &_domain {
        margin-bottom: 10px;
      }

      &_title,
      &_text {
        font-size: 14px;
        line-height: 20px;
      }

      &_remove {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .sendButton {
      width: 100%;
    }
  }

  .accessOrganization {
    button {
      width: 100%;
    }
  }

  .project-append-button__container {
    flex-direction: column;
    align-items: flex-start;

    .form-group {
      margin-bottom: 20px;
      width: 100%;

      svg {
        display: none;
      }

      .button-outline-add-felix div {
        border-left: none;
        white-space: normal;
      }
    }

    .copy-work__button {
      justify-content: center;
    }
  }

  .employee_add_buttons__container {
    .form-group {
      margin-right: 0;
    }
  }

  .rt-felix-button__container {
    .rt-felix-button__title {
      white-space: unset;
    }
  }
}

.one-line-block {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.rt-tree-select.noSelectParent {
  .rc-tree-select-tree-switcher:not(.rc-tree-select-tree-switcher-noop) + .rc-tree-select-tree-node-content-wrapper {
    pointer-events: none;
  }
}

.table-mini-select {
  padding: 6px 0px !important;
  width: 1px;
}

.link {
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  text-decoration: none;
  outline: none;
  display: flex;
  align-items: center;
  display: inline-block;
}

.link.active {
  color: var(--primary-color);
}

.link:hover {
  cursor: pointer;
  color: var(--primary-color);
}

.table-project-result {
  background-color: white;

  .dashboard-result-table__container table > thead > tr > th {
    vertical-align: middle;
  }

  .totalCount {
    text-align: right;
  }

  .next_project_version_link {
    color: var(--primary-color);
    white-space: nowrap;
  }
}

.create-project-button {
  margin-left: auto;
  // border: 4px solid transparent;
}

.select-status-filter .input-title__container-main {
  background-color: rgba(16, 24, 40, 0.05);
  border: 0px solid;
  height: 46px;
}

.flex-space-between-search {
  justify-content: flex-start;
}

.with-subtitle {
  h3 {
    margin: 0;
  }

  p {
    font-size: 0.9em;
    font-weight: 100;
  }
}

.invest-program .rc-tree-select-selection-item-content {
  white-space: normal;
}

.text-align-left {
  text-align: left;
}

.no-space-bottom {
  padding-bottom: 0;
  margin-bottom: 0;
}

.card.card-briefcase.indicator {
  border-bottom: 0.5em solid transparent;

  &.SLIGHT_DEVIATION {
    border-bottom-color: #ffd36b;
  }

  &.SIGNIFICANT_DEVIATION {
    border-bottom-color: #ff6d6d;
  }

  &.PLAN {
    border-bottom-color: #66e59b;
  }
}

.header-with-desc {

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 0;
  }

  .input-title__description {
    padding-left: 0;
  }
}

.header-gray {
  color: gray;
  font-size: 0.7em;
}

.title-badge {
  color: #7f7f7f;
  margin-left: 6px;
}

.flex {
  display: flex;
}

.leader-rating {

  &.D {
    color: #ff8080;
  }

  &.C {
    color: #ffc34d;
  }

  &.B {
    color: #20B2AA;
  }

  &.A {
    color: #5CB3FF;
  }
}

.button-group {
  margin-left: -0.7em;
  margin-right: -0.7em;

  .rt-felix-button__container,
  .margin-left-multiple {
    margin-left: 0.7em;
    margin-bottom: 0.7em;
    display: inline-block;
  }

  a.rt-felix-button__container {
    overflow: initial;
  }
}

.link-icon {
  margin-left: 0.5em;
}

.textButton {
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  color: var(--primary-color);
  font-size: 12px;
}

.r12-icon {
  text-align: center;
  font-size: 9px;
  font-weight: bold;
  color: white;
  background-color: blue;
  width: 20px;
  height: 12px;
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 10px;

  &.corrected {
    width: 45px;
  }
}

.direction_up {
  transform: rotate(180deg) !important;
}

.direction_down {
  transform: rotate(0deg) !important;
}

.direction_left {
  transform: rotate(90deg) !important;
}

.direction_right {
  transform: rotate(270deg) !important;
}

.marginBottom-10 {
  margin-bottom: 10px;
}

.marginLeft-0 {
  margin-left: 0;
}

.marginLeft-0-imp {
  margin-left: 0 !important;
}

.text-align-right {
  text-align: right;
}

.modal__buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.maxWith-160 {
  max-width: 160px;
}

.creacte-project-option.white {
  background: white;
}

.table {
  margin-bottom: initial;
}

.dashboard-result-table__container {
  margin-bottom: 1rem;

  &.no-padding {
    margin-bottom: 0;
  }
}

div.dashboard-result-table__container.input-color {

  table > thead > tr > th,
  table > tfoot > tr > td {
    background: #f1f4f6;
    border: 1px solid #e9eef1;
  }

  table > tbody > tr > td {
    position: relative;
  }
}

.hovered-svg {
  cursor: pointer;
  pointer-events: all;

  path {
    fill: black !important;
  }
}

.hovered-svg:hover {
  path {
    fill: var(--primary-color) !important;
  }
}

.table_employee_link-to-portal {
  margin-right: 5px;
}

.table_employee_unregistered_sign {
  margin-bottom: 4px;

  svg {
    fill: none;
    path {
      fill: none;
    }
  }
}

.table_employee_user_link:hover {
  cursor: pointer;
  color: var(--primary-color);
}

.table-add-employee-excel {
  display: block;
  overflow: auto;
}

td {
  .error {
    color: red;

    &.orange {
      color: #ff5e2d;
    }
  }
}

td.error {
  color: red;
}

.new_project_title_footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.version-section-link {
  margin-top: 3px;

  a,
  button {
    color: var(--primary-color);
    font-size: 12px;
  }
}

.modal-content {
  max-height: calc(100vh - 3.5rem);
}

.modal-body {
  @extend .custom-scrollbar;
  overflow-y: auto;
}

@media(max-height: 400px) {
  .modal-content {
    max-height: calc(400px - 3.5rem);
  }
}

.required-field-alert {
  border-radius: 8px;
  background: rgba(255, 182, 8, 0.10);
  width: 100%;
  padding: 16px;
  align-items: center;
  gap: 12px;
  margin-bottom: 1rem;
  display: inline-flex;

  .required-field-alert--text {
    color: var(--transparent-50-day, rgba(16, 24, 40, 0.50));
    font-variant-numeric: lining-nums tabular-nums stacked-fractions;
    font-feature-settings: "clig" off, "liga" off;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-top: 4px;
  }
}

.export-xls-link {
  border: none;
  color: var(--primary-color);
  font-size: 12px;
  line-height: 20px;
  background: transparent;
  outline: none;
  padding: 0;
}

.risk-heat-map-container {
  display: inline-block;
  margin-left: -34px;
}

.risk-heat-map-main-title {
  font-variant-numeric: lining-nums tabular-nums stacked-fractions;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  &.THREAT {
    margin-left: 45px;
    margin-bottom: 15px;
  }

  &.OPPORTUNITY {
    margin-left: 45px;
    margin-bottom: 15px;
  }
}

.risk-heat-map-title {
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums stacked-fractions;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  &.horizontal {
    margin-left: 80px;
  }

  &.vertical {
    transform-origin: center;
    transform: rotate(-90deg);
    margin-left: -4px;
    margin-top: 110px;
    position: absolute;
  }
}

.risk-heat-map-wrapper {
  display: inline-grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2px;

  .risk-heat-map-text {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    justify-content: center;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;

    &.vertical {
      transform-origin: center;
      transform: rotate(-90deg);
      align-items: flex-end;
    }
  }

  .risk-heat-map-tile {
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .risk-heat-map-badge {
      width: 30px;
      height: 30px;
      display: flex;
      border-radius: 15px;
      background: white;
      border: 1px solid black;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      font-variant-numeric: lining-nums tabular-nums stacked-fractions;
      font-feature-settings: 'clig' off, 'liga' off;
      cursor: pointer;
    }

    &.heat-green {
      background: rgba(102, 229, 155, 0.50);
    }

    &.heat-yellow {
      background: #FFE9B5
    }

    &.heat-red {
      background: #FFB6B6
    }
  }
}

.risk_report_comment_table {
  .table-mobile__line__value {
    overflow-wrap: break-word;
    hyphens: auto;
    width: 300px;
  }
}

.agree_warning_message {
  color: #ff5e2d;
  font-size: 14px;
}

.rollback_warning_message {
  .checkbox__label {
    color: #ff5e2d;
    font-size: 14px;
  }

  .checkbox__label:before {
    border-color: #ff5e2d;
  }
}

.risk-table {
  thead {
    background: #F4F4F5;

    td {
      font-size: 14px;
      line-height: 20px;
      vertical-align: top;
      padding: 5px 5px;
      border: 1px solid #e8e8ee;
      color: rgba(16, 24, 40, 0.8);
    }
  }

  tbody {
    td {
      font-size: 14px;
      line-height: 20px;
      vertical-align: top;
      padding: 5px 5px;
      border: 1px solid #e8e8ee;
    }
  }

  .input-title__container-main {
    background: inherit;
    border: none;
    padding: 0;
  }

  .input-title__block {
    padding: 0;
  }

  .title {
    width: 250px;
  }

  .data {
    width: 300px;
  }
}

.release-time-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 1rem;
}

.release-time-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 1rem;

  &.mobile {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 1rem;
    width: 100%
  }
}

.min-width-200 {
  min-width: 200px;
}

.min-width-150 {
  min-width: 150px;
}

.text-align-justify {
  text-align: justify;
}

.text-align-center {
  text-align: center;
}

.flex-gap-10 {
  display: flex;
  gap: 10px;
}

.text-bold-inline {
  font-weight: bold;
  text-wrap: nowrap;
}

.app-message-line {
  height: 20px;
  white-space: nowrap;
  background-color: white;
}

.table-page-info {
  display: flex;
  justify-content: space-between;

  .left {
    display: flex;

    .current-count {
      width: 200px;
    }

    .show-link {
      width: 200px;
      text-align: left;
    }
  }

  .total-count {
    width: 250px;
    text-align: right;
  }
}

.react-bootstrap-table
.row-expansion-style {
  padding: 0 !important;
}

.compact-row {
  --bs-gutter-x: 25px;
  --bs-gutter-y: 0;
  display: flex;
  gap: 4px;
  flex-wrap: nowrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

a:hover svg.rollback-icon {
  path {
    fill: none;
  }
}

.rollback-icon {
  &.reverted {
    transform: scaleX(-1);
  }
}

.rollback-button {
  border: none;
  outline: none;
  background: transparent;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
}

a {
  &:hover,
  &.active {
    .project-header-favorite {
      svg.stroke {
        path {
          fill: none;
          stroke: black;
        }
      }
    }
  }

  .project-header-favorite {
    &:hover,
    &.active {
      svg.stroke {
        path {
          stroke: #ffb608;
          fill: #ffb608;
        }
      }
    }
  }
}

.project-header-favorite {
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  margin-right: 0.4rem;

  &:hover,
  &.active {
    cursor: pointer;

    svg.stroke {
      path {
        stroke: #ffb608;
        fill: #ffb608;
      }
    }
  }
}

.card {
  .project-header-favorite {
    svg {
      margin-top: 5px;
      display: flex;
    }
  }
}

.header-favorites {
  width: 18px;
  height: 18px;
}