.consolidate-report-wrapper {
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - 64px);
  background-color: white !important;
  position: relative;

  .table thead th {
    vertical-align: top;
  }
}

.table-title-top {
  &.table thead th {
    vertical-align: top;
  }
}

.consolidate-report-nav {
  padding: 60px 0px 60px 10px;
  border-right: 1px solid rgba(16, 24, 40, 0.05);
  width: 230px;
  font-size: 13px;

  .project-nav__title {
    margin-left: 4px;
  }

  //статичное меню
  margin-right: -230px;
  &.static {
    position: fixed;
    top: 64px;
    bottom: 0;
    overflow: auto;
    margin-right: 0;
  }
}

.consolidate-report-nav ul {
  list-style: none;
  padding-left: 0;
  padding-right: 5px;

  & li {
    margin-bottom: 10px;

    & .active a {
      color: red;
    }
  }
}

.consolidate-report-option {
  padding: 30px 50px;
  width: 100%;
  min-height: 100%;

  //статичное меню
  &.with-nav {
    width: calc(100% - 230px);
    margin-left: 230px;
  }
}

.consolidate-report-filter-felix {
  max-width: 1000px;
}

.consolidate-report-excel {
  text-align: end;

  & a {
    text-decoration: underline;
    color: var(--primary-color);
  }
}

.consolidate-report-filter-block-felix {
  display: flex;
}

.consolidate-report-lg-modal {
  .modal-dialog {
    max-width: unset;
  }

  .modal-content {
    width: calc(100% - 40px);
  }
}

.consolidate-report-xl-modal {
  .modal-dialog {
    max-width: unset;
  }

  .modal-content {
    width: calc(100% - 40px);
  }
}

.consolidate-report-xxl-modal {
  .modal-dialog {
    max-width: unset;
  }

  .modal-content {
    width: calc(100% - 40px);
  }
}

.consolidate-report-team-load {
  .modal-dialog {
    max-width: unset;
  }

  .modal-content {
    width: calc(100% - 40px);
  }
}


.dropdown,
.dropup {
  position: relative;
}

.react-bootstrap-table th > .react-bootstrap-table-sort-order > .caret {
  margin: 10px 6.5px;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid\9;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

th:focus {
  outline: none;
  outline-style: none;
  outline-width: 0px;
}

.dashboard-table__container .react-bootstrap-table th,
.dashboard-result-table__container.no-wrap-header.react-bootstrap-table th {
  white-space: nowrap;
}

.consolidate-report-wrapper {
  .dashboard-table__container {
    .consolidate-report-table-wrapper {
      display: inline-block;
      max-width: 100%;
    }

    .consolidate-report-table-wrapper,
    .react-bootstrap-table {
      overflow: auto;
    }
  }
}

@media (max-width: 1200px) {
  .consolidate-report-filter-block-felix {
    flex-direction: column;
  }

  .consolidate-report-filter-item {
    width: 100% !important;
    flex: 0 0 100%;
    max-width: 100%;
  }

  // .report-lesson__buttons {

  //   button,
  //   a {
  //     margin: 10px 10px 0 0 !important;
  //   }
  // }
}

@media (max-width: 768px) {

  .consolidate-report-xxl-modal,
  .consolidate-report-xl-modal,
  .consolidate-report-lg-modal,
  .consolidate-report-team-load {
    .modal-title .h3 {
      font-size: 20px;
      line-height: 24px;
    }

    .modal-body {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .consolidate-report-wrapper {
    padding: 30px 72px 60px 20px;
    flex-direction: column;
  }

  .consolidate-report-nav {
    padding: 0;
    padding-bottom: 20px;
    border-right: none;
    border-bottom: 1px solid #E8E8EE;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    //статичное меню
    &.static {
      position: unset;
      top: unset;
      bottom: unset;
      overflow: unset;
    }

    &_title {
      font-size: 24px;
      line-height: 32px;
    }

    &_select {
      &__title {
        margin-bottom: 0 !important;
        width: 100%;

        .input-title__container-main {
          background: var(--primary-color);
        }

        .input-title__label,
        .select-rt__single-value,
        .select-rt__clear-indicator {
          color: rgba(255, 255, 255, 0.9)
        }

        .select-rt__single-value {
          font-size: 16px;
          line-height: 18px;
          white-space: normal;
        }

        .select-rt__indicator-separator {
          display: none;
        }

        .select-rt .select-rt__indicators {
          margin-top: 0;
          margin-right: -5px;
        }

        .input-title__block {
          padding: 0;
        }

        .select-rt .select-rt__control,
        .select-rt__value-container {
          height: 40px;
        }
      }
    }
  }

  .consolidate-report-option {
    padding: 0;
    padding-top: 20px;
    width: 100%;

    //статичное меню
    &.with-nav {
      margin-left: 0;
      width: 100%;
    }
  }

  .consolidate-report-filter {
    &_title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    &_text {
      font-size: 16px;
      line-height: 24px;
    }

    &_clear {
      font-size: 14px;
      line-height: 20px;
      color: var(--primary-color);
    }

    &-felix {
      .rt-felix-button__container {
        width: 100%;
      }

      .checkbox {
        margin-bottom: 15px;
      }
    }
  }

  .consolidate-report-filter-block-felix {
    .dashboard__switch {
      padding: 0;
    }

    .dashboard__switch-title {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .report-lesson__buttons {
    &.button-group {
      margin: 0;

      a,
      button,
      .rt-felix-load-file__container {
        text-align: center;
        margin-left: 0;
      }
    }

    .rt-felix-button-append__container,
    .rt-felix-button__container {
      width: 100%;
    }
  }

  .consolidate-report-wrapper {
    .dashboard-table__container {
      position: relative;
      padding-bottom: 40px;

      .consolidate-report-excel {
        float: none;
        position: absolute;
        bottom: 0;

        a {
          text-decoration: none;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    .dashboard-table__container {
      table {
        th {
          border-top: none;
        }
      }
    }
  }

  .consolidate-report-wrapper {
    .dashboard-table__container {
      .react-bootstrap-table {
        max-height: 500px;
      }
    }
  }
}

@media (max-width: 600px) {
  .consolidate-report-nav_select-wrapper {
    width: 100%;
  }

  .consolidate-report-xxl-modal,
  .consolidate-report-xl-modal,
  .consolidate-report-lg-modal,
  .consolidate-report-team-load {
    .modal-title .h3 {
      font-size: 16px;
      line-height: 22px;
    }

    .modal-body {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .consolidate-report-wrapper {
    padding: 20px 20px 60px;
  }

  .consolidate-report-nav {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-bottom: none;
    padding-bottom: 0;

    &_title {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 10px;
    }

    &_select {
      &__title {
        margin-bottom: 20px !important;
        width: 100%;

        .input-title__container-main {
          background: var(--primary-color);
        }

        .input-title__label,
        .select-rt__single-value,
        .select-rt__clear-indicator {
          color: rgba(255, 255, 255, 0.9)
        }

        .select-rt__single-value {
          font-size: 16px;
          line-height: 18px;
          white-space: normal;
        }

        .select-rt__indicator-separator {
          display: none;
        }

        .select-rt .select-rt__indicators {
          margin-top: 0;
          margin-right: -5px;
        }

        .input-title__block {
          padding: 0;
        }

        .select-rt .select-rt__control,
        .select-rt__value-container {
          height: 60px;
        }
      }
    }
  }

  .consolidate-report-option {
    padding-top: 0;
    width: 100%;
  }
}

.dashboard-result-table__container.pointer-row td {
  cursor: pointer;
}

.input-title__container.dashboard__switch.mini {
  padding: 0;
}

td.report-warning {
  color: red;
}

tr.report-warning {
  background: #ffefef;
  td {
    background: #ffefef;
  }
}

.table-hover tbody tr.report-warning:hover {
  background: #ffcfcf;
}

td.report-select {
  background: #f1efff;
}

.report-wrapper .h1-felix .rt-range-title__tooltip > svg {
  width: 20px;
  height: 20px;
}