.creacte-project-wrapper {
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - 64px);
  background-color: white !important;
  position: relative;
}

.creacte-project-nav {
  padding: 60px 0px 60px 50px;
  border-right: 1px solid rgba(16, 24, 40, 0.05);
  width: 230px;
  font-size: 13px;

  .project-nav__title {
    margin-left: 4px;
    padding-right: 8px;
  }

  //статичное меню
  margin-right: -230px;
  &.static {
    position: fixed;
    top: 64px;
    bottom: 0;
    overflow: auto;
    margin-right: 0;
  }
}

.creacte-project-nav-mobile {
  display: none;
}

// навигация
.creacte-project-nav ul {
  list-style: none;
  padding-left: 0;

  & li {
    margin-bottom: 30px;

    & .active a {
      color: red;
    }
  }
}

.creacte-project-option {
  padding: 20px 50px;
  width: calc(100% - 230px);
  min-height: 100%;

  //статичное меню
  &.with-nav {
    margin-left: 230px;
  }
}

.wrapper-option {
  max-width: 528px;
}

.new_project_title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.new_project_send {
  margin-bottom: 30px;
}

.form-content {
  margin-top: 32px;
}

@media (max-width: 1150px) {
  .project-date {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 1000px) {
  .creacte-project-nav-createPage {
    display: none;
  }
}

@media (max-width: 768px) {
  .creacte-project-wrapper {
    flex-direction: column;
    padding: 40px 72px 40px 20px;
  }

  .creacte-project-nav {
    display: none;
    padding: 0;
    border-right: none;
    width: 100%;
    font-size: 14px;
    line-height: 20px;

    //статичное меню
    &.static {
      position: unset;
      top: unset;
      bottom: unset;
      overflow: unset;
    }
  }

  .new_project_title_separator {
    display: none;
  }

  .creacte-project-nav-mobile {
    display: block;
    margin: 24px 0;

    .creacte-project-nav {
      display: block;
    }
  }

  .creacte-project-nav ul {
    margin-bottom: 0;
    display: flex;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    & li {
      margin-bottom: 0;
      padding-right: 20px;
      border-bottom: 2px solid rgba(16, 24, 40, 0.1);

      &.active a {
        border-bottom: 2px solid var(--primary-color);
      }

      a {
        white-space: nowrap;
        transform: translateY(2px);
        padding-bottom: 10px;
      }
    }
  }

  .creacte-project-option {
    padding: 0;
    width: 100%;

    //статичное меню
    &.with-nav {
      margin-left: 0;
    }

    .h1-felix {
      margin-top: 12px;
    }
  }

  .wrapper-option {
    max-width: 100%;

    hr {
      margin: 20px 0 40px;
    }
  }

  .form-content {
    margin-top: 20px;
  }

  .project-date {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 680px) {
  .project-date {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 600px) {
  .creacte-project-wrapper {
    padding: 20px 20px 40px;
  }

  .creacte-project-nav-mobile {
    position: relative;
    height: 38px;

    .creacte-project-nav {
      position: absolute;
      width: calc(100%);
    }
  }

  .creacte-project-option {
    .h1-felix {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .new_project_send {
    .rt-felix-button__container {
      width: 100%;
      padding: 14px;
    }
  }
}