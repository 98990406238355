.report__container {
  font-weight: 400;
  font-size: 13px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  flex-grow: 1;

  .file-add-felix {
    font-size: 1rem;
  }
}

.report-status__container {
  display: flex;
}

.project-status-procent__container {
  background: #FFFFFF;
  border: 1px solid rgba(16, 24, 40, 0.05);
  box-sizing: border-box;
  border-radius: 8px;
  width: 170px;
  padding: 0.7rem 5px;
  margin-left: 2rem;
  font-size: 14px;
  max-height: 222px;
}

.report-buttons__container {
  margin-top: 1rem;
}

.report-items__container {
  margin-top: 1rem;

  .input-title__container,
  .report-item__risk__container {
    margin-bottom: 0;
    margin-top: 12px;
  }
}

.report-item__budget-plan-fact-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
}

.report-item__break {
  width: 100%;
}

.report-item__title-escalation {
  line-height: 8px;
}

.report-item__title {
  font-size: 16px;
  font-weight: bold;
}

.report-line {
  margin: 8px 0;
  background: #F3F3F4;
}

.report-item__title__container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.report-item__container + .report-item__container {
  margin-top: 20px;
}

.report-item__comment {
  margin-top: 12px;
}

.report-item__title {
  flex-grow: 1;
}

.report-item__title-percent,
.report-item__title-team-load {
  margin-left: auto;
}

.title-program__spolier {
  margin-bottom: 1rem;
}

.title-program__spolier > .tab-title-felix {
  color: var(--primary-color);
  display: list-item;
  list-style: disc;
  font-weight: bold;
}

.title-program__spolier > .tab-data-felix > .status-list__container {
  padding: 10px 0;

}

.tab-data-felix > .report__container {
  padding-bottom: 10px;
}

.report-work__name {
  font-weight: bold;
  margin-top: .4rem;
  color: inherit;
  display: block;
}

.report-work__container {
  margin-bottom: .3rem;
}

.report-title {
  font-size: 17px;
}

.report-update__button {
  margin-bottom: 1rem;
}

.report-item__title-duration-column,
.percent-column {
  display: none;
}

@media (max-width: 1250px) {
  .report-item__title-status,
  .report-item__title-percent,
  .report-item__budget-plan,
  .report-item__budget-fact,
  .report-item__title-team-load {
    width: 100%;
    margin-left: 0 !important;
  }

  .report-work__container {
    position: relative;
    line-height: 20px;

    .report-item__title-duration-row {
      display: none;
    }

    .report-item__title-duration-column {
      display: block;
    }
  }

  .percent-row {
    display: none;
  }
  
  .percent-column {
    display: block;
  }
}

@media (max-width: 1100px) {
  .project-status-procent__container {
    display: none;
  }
}

@media (max-width: 768px) {
  .report-items__container {
    hr {
      margin: 8px 0;
    }
  }

  .title-program__spoilerWrapper {
    padding-left: 20px;
  }
}

@media (max-width: 600px) {
  .report-buttons__container {
    margin: 30px 0;
  }

  .report-update__button {
    margin: 0;
  }
}
